export default function extend(scheduler) {
  scheduler._skin_settings = {
    fix_tab_position: [1, 0],
    use_select_menu_space: [1, 0],
    wide_form: [1, 0],
    hour_size_px: [44, 42],
    displayed_event_color: ["#ff4a4a", "ffc5ab"],
    displayed_event_text_color: ["#ffef80", "7e2727"]
  };
  scheduler._skin_xy = {
    lightbox_additional_height: [90, 50],
    nav_height: [59, 22],
    bar_height: [24, 20]
  }; // material skin uses a different box sizing model than other skins, and also requires some post-processing.
  // In order to render events correctly, we need to know which box sizing model is used
  // We can detect it by styles applied, taking into account that styles may be loaded after scheduler is rendered

  scheduler._is_material_skin = function () {
    if (!scheduler.skin) {
      return checkIfMaterialSkin();
    } else {
      return (scheduler.skin + "").indexOf("material") > -1;
    }
  };

  var calculatedMaterial;

  function checkIfMaterialSkin() {
    if (calculatedMaterial === undefined) {
      var probe = document.createElement("div");
      probe.style.position = "absolute";
      probe.style.left = "-9999px";
      probe.style.top = "-9999px";
      probe.innerHTML = "<div class='dhx_cal_container'>" + "<div class='dhx_cal_scale_placeholder'>" + "</div>" + "<div>";
      document.body.appendChild(probe);
      var styles = window.getComputedStyle(probe.querySelector(".dhx_cal_scale_placeholder"));
      var position = styles.getPropertyValue('position');

      if (position === "absolute") {
        // page has skins for placeholder element from material skin
        calculatedMaterial = true;
      } else {
        calculatedMaterial = false;
      }

      setTimeout(function () {
        calculatedMaterial = null;
      }, 500);
    }

    return calculatedMaterial;
  }

  var cachedBorderBoxValue;

  function checkIfBorderBoxStyling() {
    if (scheduler._is_material_skin()) {
      return true;
    } else {
      if (cachedBorderBoxValue === undefined) {
        var probe = document.createElement("div");
        probe.style.position = "absolute";
        probe.style.left = "-9999px";
        probe.style.top = "-9999px";
        probe.innerHTML = "<div class='dhx_cal_container'>" + "<div class='dhx_cal_data'>" + "<div class='dhx_cal_event'><div class='dhx_body'></div>" + "</div>" + "<div>";
        document.body.appendChild(probe);
        var styles = window.getComputedStyle(probe.querySelector(".dhx_body"));
        var boxSizing = styles.getPropertyValue('box-sizing');
        document.body.removeChild(probe);
        cachedBorderBoxValue = !!(boxSizing === "border-box");

        if (!cachedBorderBoxValue) {
          setTimeout(function () {
            cachedBorderBoxValue = undefined;
          }, 1000); // recalculate in case scheduler initialized before skin is loaded
        }
      } else {
        return cachedBorderBoxValue;
      }
    }
  }

  function refreshAfterLoad() {
    if (scheduler._is_material_skin() || scheduler._border_box_events()) {
      return;
    }

    var oldStyling = cachedBorderBoxValue;
    cachedBorderBoxValue = undefined;
    calculatedMaterial = undefined;
    var newStyling = checkIfBorderBoxStyling(); // if box styling model changed - means scheduler was rendered before stylesheet was loaded or parsed inline
    // repaint scheduler in order to apply new styles

    if (oldStyling !== newStyling && scheduler.$container && scheduler.getState().mode) {
      scheduler.setCurrentView();
    }
  }

  window.addEventListener('DOMContentLoaded', refreshAfterLoad);
  window.addEventListener('load', refreshAfterLoad);

  scheduler._border_box_events = function () {
    return checkIfBorderBoxStyling();
  };

  scheduler._configure = function (col, data, skin) {
    for (var key in data) {
      if (typeof col[key] == "undefined") col[key] = data[key][skin];
    }
  };

  scheduler._skin_init = function () {
    if (!scheduler.skin) {
      var links = document.getElementsByTagName("link");

      for (var i = 0; i < links.length; i++) {
        var res = links[i].href.match("dhtmlxscheduler_([a-z]+).css");

        if (res) {
          scheduler.skin = res[1];
          break;
        }
      }
    }

    var set = 0;
    if (scheduler.skin && (scheduler.skin === "classic" || scheduler.skin === "glossy")) set = 1;

    if (scheduler._is_material_skin()) {
      var defaultButtonsLeft = scheduler.config.buttons_left.$inital;
      var defaultButtonsRight = scheduler.config.buttons_right.$inital;

      if (defaultButtonsLeft && scheduler.config.buttons_left.slice().join() == defaultButtonsLeft && defaultButtonsRight && scheduler.config.buttons_right.slice().join() == defaultButtonsRight) {
        var tmp = scheduler.config.buttons_left.slice();
        scheduler.config.buttons_left = scheduler.config.buttons_right.slice();
        scheduler.config.buttons_right = tmp;
      }

      scheduler.xy.event_header_height = 18; //	scheduler.xy.menu_width = 25;

      scheduler.xy.week_agenda_scale_height = 35;
      scheduler.xy.map_icon_width = 38;
      scheduler._lightbox_controls.defaults.textarea.height = 64;
      scheduler._lightbox_controls.defaults.time.height = 'auto';
    } //apply skin related settings


    this._configure(scheduler.config, scheduler._skin_settings, set);

    this._configure(scheduler.xy, scheduler._skin_xy, set);

    if (scheduler.skin === "flat") {
      scheduler.xy.scale_height = 35;

      scheduler.templates.hour_scale = function (date) {
        var min = date.getMinutes();
        min = min < 10 ? "0" + min : min;
        var html = "<span class='dhx_scale_h'>" + date.getHours() + "</span>" + "<span class='dhx_scale_m'>&nbsp;" + min + "</span>";
        return html;
      };
    } //classic skin need not any further customization


    if (set) return;
    var minic = scheduler.config.minicalendar;
    if (minic) minic.padding = 14;

    scheduler.templates.event_bar_date = function (start, end, ev) {
      return "• <b>" + scheduler.templates.event_date(start) + "</b> ";
    }; //scheduler._lightbox_template="<div class='dhx_cal_ltitle'><span class='dhx_mark'>&nbsp;</span><span class='dhx_time'></span><span class='dhx_title'></span><div class='dhx_close_icon'></div></div><div class='dhx_cal_larea'></div>";


    scheduler.attachEvent("onTemplatesReady", function () {
      var date_to_str = scheduler.date.date_to_str("%d");

      if (!scheduler.templates._old_month_day) {
        scheduler.templates._old_month_day = scheduler.templates.month_day;
      }

      var old_month_day = scheduler.templates._old_month_day;

      scheduler.templates.month_day = function (date) {
        if (this._mode == "month") {
          var label = date_to_str(date);

          if (date.getDate() == 1) {
            label = scheduler.locale.date.month_full[date.getMonth()] + " " + label;
          }

          if (+date == +scheduler.date.date_part(this._currentDate())) {
            label = scheduler.locale.labels.dhx_cal_today_button + " " + label;
          }

          return label;
        } else {
          return old_month_day.call(this, date);
        }
      };

      if (scheduler.config.fix_tab_position) {
        var navline_divs = scheduler._els["dhx_cal_navline"][0].getElementsByTagName('div');

        var minical = null; //var tabs = [];

        var last = 211;
        var positions = [14, 75, 136];
        var inc = 14;

        if (scheduler._is_material_skin()) {
          positions = [16, 103, 192];
          last = 294;
          inc = -1;
        }

        for (var i = 0; i < navline_divs.length; i++) {
          var div = navline_divs[i];
          var name = div.getAttribute("data-tab") || div.getAttribute("name");

          if (name) {
            // mode tab
            div.style.right = "auto";

            switch (name) {
              case "day":
              case "day_tab":
                div.style.left = positions[0] + "px";
                div.className += " dhx_cal_tab_first";
                break;

              case "week":
              case "week_tab":
                div.style.left = positions[1] + "px";
                break;

              case "month":
              case "month_tab":
                div.style.left = positions[2] + "px";
                div.className += " dhx_cal_tab_last";
                break;

              default:
                div.style.left = last + "px";
                div.className += " dhx_cal_tab_standalone";
                last = last + inc + div.offsetWidth;
                break;
            }

            div.className += " " + name;
          } else {
            if ((div.className || "").indexOf("dhx_minical_icon") === 0 && div.parentNode == scheduler._els["dhx_cal_navline"][0]) {
              // if default minicalendar icon
              minical = div;
            }
          }
        }

        if (minical) {
          minical.style.left = last + "px";
        }
      }
    });

    scheduler._skin_init = function () {};
  };
}