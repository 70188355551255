function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export default function extend(scheduler) {
  var defaultDomEvents = {
    event: function event(el, _event, handler) {
      if (el.addEventListener) el.addEventListener(_event, handler, false);else if (el.attachEvent) el.attachEvent("on" + _event, handler);
    },
    eventRemove: function eventRemove(el, event, handler) {
      if (el.removeEventListener) el.removeEventListener(event, handler, false);else if (el.detachEvent) el.detachEvent("on" + event, handler);
    }
  };

  var domEvents = function (addEvent, removeEvent) {
    addEvent = addEvent || defaultDomEvents.event;
    removeEvent = removeEvent || defaultDomEvents.eventRemove;
    var handlers = [];
    var eventScope = {
      attach: function attach(el, event, callback, capture) {
        handlers.push({
          element: el,
          event: event,
          callback: callback,
          capture: capture
        });
        addEvent(el, event, callback, capture);
      },
      detach: function detach(el, event, callback, capture) {
        removeEvent(el, event, callback, capture);

        for (var i = 0; i < handlers.length; i++) {
          var handler = handlers[i];

          if (handler.element === el && handler.event === event && handler.callback === callback && handler.capture === capture) {
            handlers.splice(i, 1);
            i--;
          }
        }
      },
      detachAll: function detachAll() {
        var staticArray = handlers.slice(); // original handlers array can be spliced on every iteration

        for (var i = 0; i < staticArray.length; i++) {
          var handler = staticArray[i];
          eventScope.detach(handler.element, handler.event, handler.callback, handler.capture);
          eventScope.detach(handler.element, handler.event, handler.callback, undefined);
          eventScope.detach(handler.element, handler.event, handler.callback, false);
          eventScope.detach(handler.element, handler.event, handler.callback, true);
        }

        handlers.splice(0, handlers.length);
      },
      extend: function extend() {
        return domEvents(this.event, this.eventRemove);
      }
    };
    return eventScope;
  }();

  scheduler.event = domEvents.attach;
  scheduler.eventRemove = domEvents.detach;
  scheduler._eventRemoveAll = domEvents.detachAll;
  scheduler._createDomEventScope = domEvents.extend;

  scheduler._trim = function (str) {
    var func = String.prototype.trim || function () {
      return this.replace(/^\s+|\s+$/g, "");
    };

    return func.apply(str);
  };

  scheduler._isDate = function (obj) {
    if (obj && _typeof(obj) == "object") {
      return !!(obj.getFullYear && obj.getMonth && obj.getDate);
    } else {
      return false;
    }
  };

  scheduler._isObject = function (obj) {
    return obj && _typeof(obj) == "object";
  };
}