function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import DataProcessorEvents from "./dataprocessor_hooks";
import { extendScheduler } from "./dataprocessor_hooks";
import eventable from "../../utils/eventable";
import global from "../../global";
/**
 *	@desc: constructor, data processor object
 *	@param: serverProcessorURL - url used for update
 *	@type: public
 */

export function DataProcessor(serverProcessorURL) {
  this.serverProcessor = serverProcessorURL;
  this.action_param = "!nativeeditor_status";
  this.object = null;
  this.updatedRows = []; // ids of updated rows

  this.autoUpdate = true;
  this.updateMode = "cell";
  this._tMode = "GET";
  this._headers = null;
  this._payload = null;
  this.post_delim = "_";
  this._waitMode = 0;
  this._in_progress = {};
  this._invalid = {};
  this.messages = [];
  this.styles = {
    updated: "font-weight:bold;",
    inserted: "font-weight:bold;",
    deleted: "text-decoration : line-through;",
    invalid: "background-color:FFE0E0;",
    invalid_cell: "border-bottom:2px solid red;",
    error: "color:red;",
    clear: "font-weight:normal;text-decoration:none;"
  };
  this.enableUTFencoding(true);
  eventable(this); // TODO: need to update

  return this;
}
DataProcessor.prototype = {
  setTransactionMode: function setTransactionMode(mode, total) {
    if (_typeof(mode) == "object") {
      this._tMode = mode.mode || this._tMode;

      if (mode.headers !== undefined) {
        this._headers = mode.headers;
      }

      if (mode.payload !== undefined) {
        this._payload = mode.payload;
      }

      this._tSend = !!total;
    } else {
      this._tMode = mode;
      this._tSend = total;
    }

    if (this._tMode == "REST") {
      this._tSend = false;
      this._endnm = true;
    }

    if (this._tMode === "JSON" || this._tMode === "REST-JSON") {
      this._tSend = false;
      this._endnm = true;
      this._serializeAsJson = true;
      this._headers = this._headers || {};
      this._headers["Content-Type"] = "application/json";
    } else {
      if (this._headers && !this._headers["Content-Type"]) {
        this._headers["Content-Type"] = "application/x-www-form-urlencoded";
      }
    }

    if (this._tMode === "CUSTOM") {
      this._tSend = false;
      this._endnm = true;
      this._router = mode.router;
    }
  },
  escape: function (_escape) {
    function escape(_x) {
      return _escape.apply(this, arguments);
    }

    escape.toString = function () {
      return _escape.toString();
    };

    return escape;
  }(function (data) {
    if (this._utf) return encodeURIComponent(data);else return escape(data);
  }),

  /**
   *	@desc: allows to set escaping mode
   *	@param: true - utf based escaping, simple - use current page encoding
   *	@type: public
   */
  enableUTFencoding: function enableUTFencoding(mode) {
    this._utf = !!mode;
  },

  /**
   *	@desc: allows to define, which column may trigger update
   *	@param: val - array or list of true/false values
   *	@type: public
   */
  setDataColumns: function setDataColumns(val) {
    this._columns = typeof val == "string" ? val.split(",") : val;
  },

  /**
   *	@desc: get state of updating
   *	@returns:   true - all in sync with server, false - some items not updated yet.
   *	@type: public
   */
  getSyncState: function getSyncState() {
    return !this.updatedRows.length;
  },

  /**
   *	@desc: enable/disable named field for data syncing, will use column ids for grid
   *	@param:   mode - true/false
   *	@type: public
   */
  enableDataNames: function enableDataNames(mode) {
    this._endnm = !!mode;
  },

  /**
   *	@desc: enable/disable mode , when only changed fields and row id send to the server side, instead of all fields in default mode
   *	@param:   mode - true/false
   *	@type: public
   */
  enablePartialDataSend: function enablePartialDataSend(mode) {
    this._changed = !!mode;
  },

  /**
   *	@desc: set if rows should be send to server automaticaly
   *	@param: mode - "row" - based on row selection changed, "cell" - based on cell editing finished, "off" - manual data sending
   *	@type: public
   */
  setUpdateMode: function setUpdateMode(mode, dnd) {
    this.autoUpdate = mode == "cell";
    this.updateMode = mode;
    this.dnd = dnd;
  },
  ignore: function ignore(code, master) {
    this._silent_mode = true;
    code.call(master || window);
    this._silent_mode = false;
  },

  /**
   *	@desc: mark row as updated/normal. check mandatory fields,initiate autoupdate (if turned on)
   *	@param: rowId - id of row to set update-status for
   *	@param: state - true for "updated", false for "not updated"
   *	@param: mode - update mode name
   *	@type: public
   */
  setUpdated: function setUpdated(rowId, state, mode) {
    if (this._silent_mode) return;
    var ind = this.findRow(rowId);
    mode = mode || "updated";
    var existing = this.$scheduler.getUserData(rowId, this.action_param);
    if (existing && mode == "updated") mode = existing;

    if (state) {
      this.set_invalid(rowId, false); //clear previous error flag

      this.updatedRows[ind] = rowId;
      this.$scheduler.setUserData(rowId, this.action_param, mode);
      if (this._in_progress[rowId]) this._in_progress[rowId] = "wait";
    } else {
      if (!this.is_invalid(rowId)) {
        this.updatedRows.splice(ind, 1);
        this.$scheduler.setUserData(rowId, this.action_param, "");
      }
    }

    this.markRow(rowId, state, mode);
    if (state && this.autoUpdate) this.sendData(rowId);
  },
  markRow: function markRow(id, state, mode) {
    var str = "";
    var invalid = this.is_invalid(id);

    if (invalid) {
      str = this.styles[invalid];
      state = true;
    }

    if (this.callEvent("onRowMark", [id, state, mode, invalid])) {
      //default logic
      str = this.styles[state ? mode : "clear"] + str;

      this.$scheduler[this._methods[0]](id, str);

      if (invalid && invalid.details) {
        str += this.styles[invalid + "_cell"];

        for (var i = 0; i < invalid.details.length; i++) {
          if (invalid.details[i]) this.$scheduler[this._methods[1]](id, i, str);
        }
      }
    }
  },
  getActionByState: function getActionByState(state) {
    if (state === "inserted") {
      return "create";
    }

    if (state === "updated") {
      return "update";
    }

    if (state === "deleted") {
      return "delete";
    }

    return "update";
  },
  getState: function getState(id) {
    return this.$scheduler.getUserData(id, this.action_param);
  },
  is_invalid: function is_invalid(id) {
    return this._invalid[id];
  },
  set_invalid: function set_invalid(id, mode, details) {
    if (details) mode = {
      value: mode,
      details: details,
      toString: function toString() {
        return this.value.toString();
      }
    };
    this._invalid[id] = mode;
  },

  /**
   *	@desc: check mandatory fields and varify values of cells, initiate update (if specified)
   *	@param: rowId - id of row to set update-status for
   *	@type: public
   */
  checkBeforeUpdate: function checkBeforeUpdate(rowId) {
    return true;
  },

  /**
   *	@desc: send row(s) values to server
   *	@param: rowId - id of row which data to send. If not specified, then all "updated" rows will be send
   *	@type: public
   */
  sendData: function sendData(rowId) {
    if (this.$scheduler.editStop) this.$scheduler.editStop();
    if (typeof rowId == "undefined" || this._tSend) return this.sendAllData();
    if (this._in_progress[rowId]) return false;
    this.messages = [];
    if (!this.checkBeforeUpdate(rowId) && this.callEvent("onValidationError", [rowId, this.messages])) return false;

    this._beforeSendData(this._getRowData(rowId), rowId);
  },
  _beforeSendData: function _beforeSendData(data, rowId) {
    if (!this.callEvent("onBeforeUpdate", [rowId, this.getState(rowId), data])) return false;

    this._sendData(data, rowId);
  },
  serialize: function serialize(data, id) {
    if (this._serializeAsJson) {
      return this._serializeAsJSON(data);
    }

    if (typeof data == "string") return data;
    if (typeof id != "undefined") return this.serialize_one(data, "");else {
      var stack = [];
      var keys = [];

      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          stack.push(this.serialize_one(data[key], key + this.post_delim));
          keys.push(key);
        }
      }

      stack.push("ids=" + this.escape(keys.join(",")));
      if (this.$scheduler.security_key) stack.push("dhx_security=" + this.$scheduler.security_key);
      return stack.join("&");
    }
  },
  serialize_one: function serialize_one(data, pref) {
    if (typeof data == "string") return data;
    var stack = [];
    var serialized = "";

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        if ((key == "id" || key == this.action_param) && this._tMode == "REST") continue;

        if (typeof data[key] === "string" || typeof data[key] === "number") {
          serialized = data[key];
        } else {
          serialized = JSON.stringify(data[key]);
        }

        stack.push(this.escape((pref || "") + key) + "=" + this.escape(serialized));
      }
    }

    return stack.join("&");
  },
  _applyPayload: function _applyPayload(url) {
    var ajax = this.$scheduler.ajax;
    if (this._payload) for (var key in this._payload) {
      url = url + ajax.urlSeparator(url) + this.escape(key) + "=" + this.escape(this._payload[key]);
    }
    return url;
  },
  _sendData: function _sendData(dataToSend, rowId) {
    if (!dataToSend) {
      return; // nothing to send
    }

    if (!this.callEvent("onBeforeDataSending", rowId ? [rowId, this.getState(rowId), dataToSend] : [null, null, dataToSend])) {
      return false;
    }

    if (rowId) {
      this._in_progress[rowId] = new Date().valueOf();
    }

    var self = this;
    var ajax = this.$scheduler.ajax;

    if (this._tMode === "CUSTOM") {
      var state = this.getState(rowId);
      var action = this.getActionByState(state);

      var _onResolvedCreateUpdate = function _onResolvedCreateUpdate(tag) {
        if (tag && tag.responseText && tag.setRequestHeader) {
          if (tag.status !== 200) {
            action = "error";
          }

          try {
            tag = JSON.parse(tag.responseText);
          } catch (e) {}
        }

        action = action || "updated";
        var sid = rowId;
        var tid = rowId;

        if (tag) {
          action = tag.action || state;
          sid = tag.sid || sid;
          tid = tag.id || tag.tid || tid;
        }

        self.afterUpdateCallback(sid, tid, action, tag);
      };

      var actionPromise;

      if (this._router instanceof Function) {
        actionPromise = this._router("event", action, dataToSend, rowId);
      } else {
        switch (state) {
          case "inserted":
            actionPromise = this._router.create(dataToSend);
            break;

          case "deleted":
            actionPromise = this._router["delete"](rowId);
            break;

          default:
            actionPromise = this._router.update(dataToSend, rowId);
            break;
        }
      }

      if (actionPromise) {
        // neither promise nor {tid: newId} response object
        if (!actionPromise.then && actionPromise.id === undefined && actionPromise.tid === undefined && actionPromise.action === undefined) {
          throw new Error("Incorrect router return value. A Promise or a response object is expected");
        }

        if (actionPromise.then) {
          actionPromise.then(_onResolvedCreateUpdate)["catch"](function (error) {
            if (error && error.action) {
              _onResolvedCreateUpdate(error);
            } else {
              _onResolvedCreateUpdate({
                action: "error",
                value: error
              });
            }
          });
        } else {
          // custom method may return a response object in case of sync action
          _onResolvedCreateUpdate(actionPromise);
        }
      } else {
        _onResolvedCreateUpdate(null);
      }

      return;
    }

    var queryParams = {
      callback: function callback(xml) {
        var ids = [];

        if (rowId) {
          ids.push(rowId);
        } else if (dataToSend) {
          for (var key in dataToSend) {
            ids.push(key);
          }
        }

        return self.afterUpdate(self, xml, ids);
      },
      headers: self._headers
    };
    var urlParams = this.serverProcessor + (this._user ? ajax.urlSeparator(this.serverProcessor) + ["dhx_user=" + this._user, "dhx_version=" + this.$scheduler.getUserData(0, "version")].join("&") : "");

    var url = this._applyPayload(urlParams);

    var data;

    switch (this._tMode) {
      case "GET":
        data = this._cleanupArgumentsBeforeSend(dataToSend);
        queryParams.url = url + ajax.urlSeparator(url) + this.serialize(data, rowId);
        queryParams.method = "GET";
        break;

      case "POST":
        data = this._cleanupArgumentsBeforeSend(dataToSend);
        queryParams.url = url;
        queryParams.method = "POST";
        queryParams.data = this.serialize(data, rowId);
        break;

      case "JSON":
        data = {};

        var preprocessedData = this._cleanupItemBeforeSend(dataToSend);

        for (var key in preprocessedData) {
          if (key === this.action_param || key === "id" || key === "gr_id") {
            continue;
          }

          data[key] = preprocessedData[key];
        }

        queryParams.url = url;
        queryParams.method = "POST";
        queryParams.data = JSON.stringify({
          id: rowId,
          action: dataToSend[this.action_param],
          data: data
        });
        break;

      case "REST":
      case "REST-JSON":
        url = urlParams.replace(/(&|\?)editing=true/, "");
        data = "";

        switch (this.getState(rowId)) {
          case "inserted":
            queryParams.method = "POST";
            queryParams.data = this.serialize(dataToSend, rowId);
            break;

          case "deleted":
            queryParams.method = "DELETE";
            url = url + (url.slice(-1) === "/" ? "" : "/") + rowId;
            break;

          default:
            queryParams.method = "PUT";
            queryParams.data = this.serialize(dataToSend, rowId);
            url = url + (url.slice(-1) === "/" ? "" : "/") + rowId;
            break;
        }

        queryParams.url = this._applyPayload(url);
        break;
    }

    this._waitMode++;
    return ajax.query(queryParams);
  },
  sendAllData: function sendAllData() {
    if (!this.updatedRows.length || this.updateMode === "off") {
      // FIXME: need to leave checking 'this.updateMode === "off"'?
      return;
    }

    this.messages = [];
    var valid = true;

    this._forEachUpdatedRow(function (rowId) {
      valid = valid && this.checkBeforeUpdate(rowId);
    });

    if (!valid && !this.callEvent("onValidationError", ["", this.messages])) {
      return false;
    }

    if (this._tSend) {
      this._sendData(this._getAllData());
    } else {
      this._forEachUpdatedRow(function (rowId) {
        if (!this._in_progress[rowId]) {
          if (this.is_invalid(rowId)) {
            return;
          }

          this._beforeSendData(this._getRowData(rowId), rowId);
        }
      });
    }
  },
  _getAllData: function _getAllData(rowId) {
    var out = {};
    var has_one = false;

    this._forEachUpdatedRow(function (id) {
      if (this._in_progress[id] || this.is_invalid(id)) {
        return;
      }

      var row = this._getRowData(id);

      if (!this.callEvent("onBeforeUpdate", [id, this.getState(id), row])) {
        return;
      }

      out[id] = row;
      has_one = true;
      this._in_progress[id] = new Date().valueOf();
    });

    return has_one ? out : null;
  },
  findRow: function findRow(pattern) {
    var i = 0;

    for (i = 0; i < this.updatedRows.length; i++) {
      if (pattern == this.updatedRows[i]) break;
    }

    return i;
  },

  /**
   *	@desc: define custom actions
   *	@param: name - name of action, same as value of action attribute
   *	@param: handler - custom function, which receives a XMl response content for action
   *	@type: private
   */
  defineAction: function defineAction(name, handler) {
    if (!this._uActions) this._uActions = {};
    this._uActions[name] = handler;
  },

  /**
   *	 @desc: used in combination with setOnBeforeUpdateHandler to create custom client-server transport system
   *	 @param: sid - id of item before update
   *	 @param: tid - id of item after up0ate
   *	 @param: action - action name
   *	 @type: public
   *	 @topic: 0
   */
  afterUpdateCallback: function afterUpdateCallback(sid, tid, action, btag) {
    if (!this.$scheduler) {
      // destructor has been called before the callback
      return;
    }

    var marker = sid;
    var correct = action !== "error" && action !== "invalid";

    if (!correct) {
      this.set_invalid(sid, action);
    }

    if (this._uActions && this._uActions[action] && !this._uActions[action](btag)) {
      return delete this._in_progress[marker];
    }

    if (this._in_progress[marker] !== "wait") {
      this.setUpdated(sid, false);
    }

    var originalSid = sid;

    switch (action) {
      case "inserted":
      case "insert":
        if (tid != sid) {
          this.setUpdated(sid, false);

          this.$scheduler[this._methods[2]](sid, tid);

          sid = tid;
        }

        break;

      case "delete":
      case "deleted":
        this.$scheduler.setUserData(sid, this.action_param, "true_deleted");

        this.$scheduler[this._methods[3]](sid, tid);

        delete this._in_progress[marker];
        return this.callEvent("onAfterUpdate", [sid, action, tid, btag]);
    }

    if (this._in_progress[marker] !== "wait") {
      if (correct) {
        this.$scheduler.setUserData(sid, this.action_param, "");
      }

      delete this._in_progress[marker];
    } else {
      delete this._in_progress[marker];
      this.setUpdated(tid, true, this.$scheduler.getUserData(sid, this.action_param));
    }

    this.callEvent("onAfterUpdate", [originalSid, action, tid, btag]);
  },
  _errorResponse: function _errorResponse(xml, id) {
    if (this.$scheduler && this.$scheduler.callEvent) {
      this.$scheduler.callEvent("onSaveError", [id, xml.xmlDoc]);
    }

    return this.cleanUpdate(id);
  },
  _setDefaultTransactionMode: function _setDefaultTransactionMode() {
    if (this.serverProcessor) {
      this.setTransactionMode("POST", true);
      this.serverProcessor += (this.serverProcessor.indexOf("?") !== -1 ? "&" : "?") + "editing=true";
      this._serverProcessor = this.serverProcessor;
    }
  },

  /**
   *	@desc: response from server
   *	@param: xml - XMLLoader object with response XML
   *	@type: private
   */
  afterUpdate: function afterUpdate(that, xml, id) {
    var ajax = this.$scheduler.ajax;

    if (xml.xmlDoc.status !== 200) {
      this._errorResponse(xml, id);

      return;
    } // try to use json first


    var tag;

    try {
      tag = JSON.parse(xml.xmlDoc.responseText);
    } catch (e) {
      // empty response also can be processed by json handler
      if (!xml.xmlDoc.responseText.length) {
        tag = {};
      }
    }

    if (tag) {
      var action = tag.action || this.getState(id) || "updated";
      var sid = tag.sid || id[0];
      var tid = tag.tid || id[0];
      that.afterUpdateCallback(sid, tid, action, tag);
      that.finalizeUpdate();
      return;
    } // xml response


    var top = ajax.xmltop("data", xml.xmlDoc); // fix incorrect content type in IE

    if (!top) {
      return this._errorResponse(xml, id);
    }

    var atag = ajax.xpath("//data/action", top);

    if (!atag.length) {
      return this._errorResponse(xml, id);
    }

    for (var i = 0; i < atag.length; i++) {
      var btag = atag[i];
      var action = btag.getAttribute("type");
      var sid = btag.getAttribute("sid");
      var tid = btag.getAttribute("tid");
      that.afterUpdateCallback(sid, tid, action, btag);
    }

    that.finalizeUpdate();
  },
  cleanUpdate: function cleanUpdate(id) {
    if (id) for (var i = 0; i < id.length; i++) {
      delete this._in_progress[id[i]];
    }
  },
  finalizeUpdate: function finalizeUpdate() {
    if (this._waitMode) this._waitMode--;
    this.callEvent("onAfterUpdateFinish", []);
    if (!this.updatedRows.length) this.callEvent("onFullSync", []);
  },

  /**
   *	@desc: initializes data-processor
   *	@param: scheduler - dhtmlxScheduler object to attach this data-processor to
   *	@type: public
   */
  init: function init(scheduler) {
    if (this._initialized) {
      return;
    }

    this.$scheduler = scheduler;

    if (this.$scheduler._dp_init) {
      this.$scheduler._dp_init(this);
    }

    this._setDefaultTransactionMode();

    this._methods = this._methods || ["_set_event_text_style", "", "_dp_change_event_id", "_dp_hook_delete"];
    extendScheduler(this.$scheduler, this);
    var dataProcessorEvents = new DataProcessorEvents(this.$scheduler, this);
    dataProcessorEvents.attach();
    this.attachEvent("onDestroy", function () {
      delete this._getRowData;
      delete this.$scheduler._dp;
      delete this.$scheduler._dataprocessor;
      delete this.$scheduler._set_event_text_style;
      delete this.$scheduler._dp_change_event_id;
      delete this.$scheduler._dp_hook_delete;
      delete this.$scheduler;
      dataProcessorEvents.detach();
    });
    this.$scheduler.callEvent("onDataProcessorReady", [this]);
    this._initialized = true;
    scheduler._dataprocessor = this;
  },
  setOnAfterUpdate: function setOnAfterUpdate(ev) {
    this.attachEvent("onAfterUpdate", ev);
  },
  setOnBeforeUpdateHandler: function setOnBeforeUpdateHandler(func) {
    this.attachEvent("onBeforeDataSending", func);
  },

  /* starts autoupdate mode
  	@param interval time interval for sending update requests
  */
  setAutoUpdate: function setAutoUpdate(interval, user) {
    interval = interval || 2000;
    this._user = user || new Date().valueOf();
    this._need_update = false; //this._loader = null;

    this._update_busy = false;
    this.attachEvent("onAfterUpdate", function (sid, action, tid, xml_node) {
      this.afterAutoUpdate(sid, action, tid, xml_node);
    });
    this.attachEvent("onFullSync", function () {
      this.fullSync();
    });
    var self = this;
    global.setInterval(function () {
      self.loadUpdate();
    }, interval);
  },

  /* process updating request answer
  	if status == collision version is depricated
  	set flag for autoupdating immidiatly
  */
  afterAutoUpdate: function afterAutoUpdate(sid, action, tid, xml_node) {
    if (action == 'collision') {
      this._need_update = true;
      return false;
    } else {
      return true;
    }
  },

  /* callback function for onFillSync event
  	call update function if it's need
  */
  fullSync: function fullSync() {
    if (this._need_update) {
      this._need_update = false;
      this.loadUpdate();
    }

    return true;
  },

  /* sends query to the server and call callback function
  */
  getUpdates: function getUpdates(url, callback) {
    var ajax = this.$scheduler.ajax;
    if (this._update_busy) return false;else this._update_busy = true;
    ajax.get(url, callback);
  },

  /* returns xml node value
  	@param node
  		xml node
  */
  _getXmlNodeValue: function _getXmlNodeValue(node) {
    if (node.firstChild) {
      return node.firstChild.nodeValue;
    }

    return "";
  },

  /* loads updates and processes them
  */
  loadUpdate: function loadUpdate() {
    var self = this;
    var ajax = this.$scheduler.ajax;
    var version = this.$scheduler.getUserData(0, "version");
    var url = this.serverProcessor + ajax.urlSeparator(this.serverProcessor) + ["dhx_user=" + this._user, "dhx_version=" + version].join("&");
    url = url.replace("editing=true&", "");
    this.getUpdates(url, function (xml) {
      var vers = ajax.xpath("//userdata", xml);
      self.$scheduler.setUserData(0, "version", self._getXmlNodeValue(vers[0]));
      var updates = ajax.xpath("//update", xml);

      if (updates.length) {
        self._silent_mode = true;

        for (var i = 0; i < updates.length; i++) {
          var status = updates[i].getAttribute("status");
          var id = updates[i].getAttribute("id");
          var parent = updates[i].getAttribute("parent");

          switch (status) {
            case "inserted":
              this.callEvent("insertCallback", [updates[i], id, parent]);
              break;

            case "updated":
              this.callEvent("updateCallback", [updates[i], id, parent]);
              break;

            case "deleted":
              this.callEvent("deleteCallback", [updates[i], id, parent]);
              break;
          }
        }

        self._silent_mode = false;
      }

      self._update_busy = false;
      self = null;
    });
  },
  destructor: function destructor() {
    this.callEvent("onDestroy", []);
    this.detachAllEvents();
    this.updatedRows = [];
    this._in_progress = {};
    this._invalid = {};
    this._headers = null;
    this._payload = null;
    delete this._initialized;
  },
  url: function url(_url) {
    this.serverProcessor = this._serverProcessor = _url;
  },
  _serializeAsJSON: function _serializeAsJSON(data) {
    if (typeof data === "string") {
      return data;
    }

    var copy = this.$scheduler.utils.copy(data);

    if (this._tMode === "REST-JSON") {
      delete copy.id;
      delete copy[this.action_param];
    }

    return JSON.stringify(copy);
  },
  // GET/POST/JSON modes of the dataProcessor didn't send the whole data items in 'delete' requests
  // clear extra info from the data in order not to change the request format
  _cleanupArgumentsBeforeSend: function _cleanupArgumentsBeforeSend(dataToSend) {
    var processedData;

    if (dataToSend[this.action_param] === undefined) {
      // hash of updated items, and not an individual item
      processedData = {};

      for (var i in dataToSend) {
        processedData[i] = this._cleanupArgumentsBeforeSend(dataToSend[i]);
      }
    } else {
      processedData = this._cleanupItemBeforeSend(dataToSend);
    }

    return processedData;
  },
  _cleanupItemBeforeSend: function _cleanupItemBeforeSend(updatedItem) {
    var output = null;

    if (updatedItem) {
      if (updatedItem[this.action_param] === "deleted") {
        output = {};
        output.id = updatedItem.id;
        output[this.action_param] = updatedItem[this.action_param];
      } else {
        output = updatedItem;
      }
    }

    return output;
  },
  _forEachUpdatedRow: function _forEachUpdatedRow(code) {
    var updatedRows = this.updatedRows.slice();

    for (var i = 0; i < updatedRows.length; i++) {
      var rowId = updatedRows[i];

      if (this.$scheduler.getUserData(rowId, this.action_param)) {
        code.call(this, rowId);
      }
    }
  },
  _prepareDataItem: function _prepareDataItem(item) {
    var processedItem = {};
    var scheduler = this.$scheduler;
    var copy = scheduler.utils.copy(item);

    for (var i in copy) {
      if (i.indexOf("_") === 0) {
        continue;
      } else if (copy[i]) {
        if (copy[i].getUTCFullYear) {
          processedItem[i] = scheduler._helpers.formatDate(copy[i]);
        } else if (_typeof(copy[i]) == "object") {
          processedItem[i] = this._prepareDataItem(copy[i]);
        } else if (copy[i] === null) {
          processedItem[i] = "";
        } else {
          processedItem[i] = copy[i];
        }
      }
    }

    processedItem[this.action_param] = scheduler.getUserData(item.id, this.action_param);
    return processedItem;
  },
  _getRowData: function _getRowData(id) {
    var dataItem = this.$scheduler.getEvent(id);

    if (!dataItem) {
      dataItem = {
        id: id
      };
    }

    return this._prepareDataItem(dataItem);
  }
};
export default function extend(scheduler) {
  scheduler.createDataProcessor = function (config) {
    var router;
    var tMode;

    if (config instanceof Function) {
      router = config;
    } else if (config.hasOwnProperty("router")) {
      router = config.router;
    }

    if (router) {
      tMode = "CUSTOM";
    } else {
      tMode = config.mode || "REST-JSON";
    }

    var dp = new DataProcessor(config.url);
    dp.init(scheduler);
    dp.setTransactionMode({
      mode: tMode,
      router: router
    }, config.batchUpdate); // FIXME: config.batchUpdate where it is explained?

    return dp;
  };

  scheduler.DataProcessor = DataProcessor;
} //var dataProcessor = global.dataProcessor = DataProcessor; // for old