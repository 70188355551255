function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

export default function extend(scheduler) {
  scheduler._events = {};

  scheduler.clearAll = function () {
    this._events = {};
    this._loaded = {};
    this._edit_id = null;
    this._select_id = null;
    this._drag_id = null;
    this._drag_mode = null;
    this._drag_pos = null;
    this._new_event = null;
    this.clear_view();
    this.callEvent("onClearAll", []);
  };

  scheduler.addEvent = function (start_date, end_date, text, id, extra_data) {
    if (!arguments.length) return this.addEventNow();
    var ev = start_date;

    if (arguments.length != 1) {
      ev = extra_data || {};
      ev.start_date = start_date;
      ev.end_date = end_date;
      ev.text = text;
      ev.id = id;
    }

    ev.id = ev.id || scheduler.uid();
    ev.text = ev.text || "";
    if (typeof ev.start_date == "string") ev.start_date = this.templates.api_date(ev.start_date);
    if (typeof ev.end_date == "string") ev.end_date = this.templates.api_date(ev.end_date);
    var d = (this.config.event_duration || this.config.time_step) * 60000;
    if (ev.start_date.valueOf() == ev.end_date.valueOf()) ev.end_date.setTime(ev.end_date.valueOf() + d);
    ev.start_date.setMilliseconds(0);
    ev.end_date.setMilliseconds(0);
    ev._timed = this.isOneDayEvent(ev);
    var is_new = !this._events[ev.id];
    this._events[ev.id] = ev;
    this.event_updated(ev);
    if (!this._loading) this.callEvent(is_new ? "onEventAdded" : "onEventChanged", [ev.id, ev]);
    return ev.id;
  };

  scheduler.deleteEvent = function (id, silent) {
    var ev = this._events[id];
    if (!silent && (!this.callEvent("onBeforeEventDelete", [id, ev]) || !this.callEvent("onConfirmedBeforeEventDelete", [id, ev]))) return;

    if (ev) {
      if (scheduler.getState().select_id == id) {
        scheduler.unselect();
      }

      delete this._events[id];
      this.event_updated(ev);

      if (this._drag_id == ev.id) {
        this._drag_id = null;
        this._drag_mode = null;
        this._drag_pos = null;
      }
    }

    this.callEvent("onEventDeleted", [id, ev]);
  };

  scheduler.getEvent = function (id) {
    return this._events[id];
  };

  scheduler.setEvent = function (id, hash) {
    if (!hash.id) hash.id = id;
    this._events[id] = hash;
  };

  scheduler.for_rendered = function (id, method) {
    for (var i = this._rendered.length - 1; i >= 0; i--) {
      if (this._rendered[i].getAttribute(this.config.event_attribute) == id) method(this._rendered[i], i);
    }
  };

  scheduler.changeEventId = function (id, new_id) {
    if (id == new_id) return;
    var ev = this._events[id];

    if (ev) {
      ev.id = new_id;
      this._events[new_id] = ev;
      delete this._events[id];
    }

    this.for_rendered(id, function (r) {
      r.setAttribute("event_id", new_id); // for backward compatibility

      r.setAttribute(scheduler.config.event_attribute, new_id);
    });
    if (this._select_id == id) this._select_id = new_id;
    if (this._edit_id == id) this._edit_id = new_id; //if (this._drag_id==id) this._drag_id=new_id;

    this.callEvent("onEventIdChange", [id, new_id]);
  };

  (function () {
    var attrs = ["text", "Text", "start_date", "StartDate", "end_date", "EndDate"];

    var create_getter = function create_getter(name) {
      return function (id) {
        return scheduler.getEvent(id)[name];
      };
    };

    var create_setter = function create_setter(name) {
      return function (id, value) {
        var ev = scheduler.getEvent(id);
        ev[name] = value;
        ev._changed = true;
        ev._timed = this.isOneDayEvent(ev);
        scheduler.event_updated(ev, true);
      };
    };

    for (var i = 0; i < attrs.length; i += 2) {
      scheduler["getEvent" + attrs[i + 1]] = create_getter(attrs[i]);
      scheduler["setEvent" + attrs[i + 1]] = create_setter(attrs[i]);
    }
  })();

  scheduler.event_updated = function (ev, force) {
    if (this.is_visible_events(ev)) this.render_view_data();else this.clear_event(ev.id);
  };

  scheduler.is_visible_events = function (ev) {
    if (!this._min_date || !this._max_date) {
      return false;
    } //if in displayed dates


    var in_visible_range = ev.start_date.valueOf() < this._max_date.valueOf() && this._min_date.valueOf() < ev.end_date.valueOf();

    if (in_visible_range) {
      //end dates are not between last/first hours
      var evFirstHour = ev.start_date.getHours(),
          evLastHour = ev.end_date.getHours() + ev.end_date.getMinutes() / 60,
          lastHour = this.config.last_hour,
          firstHour = this.config.first_hour;
      var end_dates_visible = this._table_view || !((evLastHour > lastHour || evLastHour <= firstHour) && (evFirstHour >= lastHour || evFirstHour < firstHour));

      if (end_dates_visible) {
        return true;
      } else {
        //event is bigger than area hidden between last/first hours
        var event_duration = (ev.end_date.valueOf() - ev.start_date.valueOf()) / (1000 * 60 * 60),
            //hours
        hidden_duration = 24 - (this.config.last_hour - this.config.first_hour);
        return !!(event_duration > hidden_duration || evFirstHour < lastHour && evLastHour > firstHour);
      }
    } else {
      return false;
    }
  };

  scheduler.isOneDayEvent = function (ev) {
    // decrease by one ms so events that ends on midnight on the next day were still considered one day events
    // e.g. (09-02-2018 19:00 - 10-02-2018 00:00)
    // events >= 24h long are considered multiday
    var checkEndDate = new Date(ev.end_date.valueOf() - 1);
    return ev.start_date.getFullYear() === checkEndDate.getFullYear() && ev.start_date.getMonth() === checkEndDate.getMonth() && ev.start_date.getDate() === checkEndDate.getDate() && ev.end_date.valueOf() - ev.start_date.valueOf() < 1000 * 60 * 60 * 24;
  };

  scheduler.get_visible_events = function (only_timed) {
    //not the best strategy for sure
    var stack = [];

    for (var id in this._events) {
      if (this.is_visible_events(this._events[id])) if (!only_timed || this._events[id]._timed) if (this.filter_event(id, this._events[id])) stack.push(this._events[id]);
    }

    return stack;
  };

  scheduler.filter_event = function (id, ev) {
    var filter = this["filter_" + this._mode];
    return filter ? filter(id, ev) : true;
  };

  scheduler._is_main_area_event = function (ev) {
    return !!ev._timed;
  };

  scheduler.render_view_data = function (evs, hold) {
    var full = false;

    if (!evs) {
      full = true;

      if (this._not_render) {
        this._render_wait = true;
        return;
      }

      this._render_wait = false;
      this.clear_view();
      evs = this.get_visible_events(!(this._table_view || this.config.multi_day));
    }

    for (var i = 0, len = evs.length; i < len; i++) {
      this._recalculate_timed(evs[i]);
    }

    if (this.config.multi_day && !this._table_view) {
      var tvs = [];
      var tvd = [];

      for (var i = 0; i < evs.length; i++) {
        if (this._is_main_area_event(evs[i])) tvs.push(evs[i]);else tvd.push(evs[i]);
      }

      if (!this._els['dhx_multi_day']) {
        var message = scheduler._commonErrorMessages.unknownView(this._mode);

        throw new Error(message);
      } // multiday events


      this._rendered_location = this._els['dhx_multi_day'][0];
      this._table_view = true;
      this.render_data(tvd, hold);
      this._table_view = false; // normal events

      this._rendered_location = this._els['dhx_cal_data'][0];
      this._table_view = false;
      this.render_data(tvs, hold);
    } else {
      var buffer = document.createDocumentFragment();
      var renderedLocation = this._els['dhx_cal_data'][0];
      this._rendered_location = buffer;
      this.render_data(evs, hold);
      renderedLocation.appendChild(buffer);
      this._rendered_location = renderedLocation;
    }

    if (full) {
      this.callEvent("onDataRender", []);
    }
  };

  scheduler._view_month_day = function (e) {
    var date = scheduler.getActionData(e).date;
    if (!scheduler.callEvent("onViewMoreClick", [date])) return;
    scheduler.setCurrentView(date, "day");
  };

  scheduler._render_month_link = function (ev) {
    var parent = this._rendered_location;

    var toRender = this._lame_clone(ev); //render links in each cell of multiday events


    for (var d = ev._sday; d < ev._eday; d++) {
      toRender._sday = d;
      toRender._eday = d + 1;
      var date = scheduler.date;
      var curr = scheduler._min_date;
      curr = date.add(curr, toRender._sweek, "week");
      curr = date.add(curr, toRender._sday, "day");
      var count = scheduler.getEvents(curr, date.add(curr, 1, "day")).length;

      var pos = this._get_event_bar_pos(toRender);

      var widt = pos.x2 - pos.x;
      var el = document.createElement("div");

      el.onclick = function (e) {
        scheduler._view_month_day(e);
      };

      el.className = "dhx_month_link";
      el.style.top = pos.y + "px";
      el.style.left = pos.x + "px";
      el.style.width = widt + "px";
      el.innerHTML = scheduler.templates.month_events_link(curr, count);

      this._rendered.push(el);

      parent.appendChild(el);
    }
  };

  scheduler._recalculate_timed = function (id) {
    if (!id) return;
    var ev;
    if (_typeof(id) != "object") ev = this._events[id];else ev = id;
    if (!ev) return;
    ev._timed = scheduler.isOneDayEvent(ev);
  };

  scheduler.attachEvent("onEventChanged", scheduler._recalculate_timed);
  scheduler.attachEvent("onEventAdded", scheduler._recalculate_timed);

  scheduler.render_data = function (evs, hold) {
    evs = this._pre_render_events(evs, hold);
    var containers = {};

    for (var i = 0; i < evs.length; i++) {
      if (this._table_view) {
        if (scheduler._mode != 'month') {
          this.render_event_bar(evs[i]); //may be multiday section on other views
        } else {
          var max_evs = scheduler.config.max_month_events;

          if (max_evs !== max_evs * 1 || evs[i]._sorder < max_evs) {
            //of max number events per month cell is set and event can be rendered
            this.render_event_bar(evs[i]);
          } else if (max_evs !== undefined && evs[i]._sorder == max_evs) {
            //render 'view more' links
            scheduler._render_month_link(evs[i]);
          } else {//do not render events with ordinal number > maximum events per cell
          }
        }
      } else {
        var ev = evs[i];
        var parent = scheduler.locate_holder(ev._sday);
        if (!parent) continue; //attempt to render non-visible event

        if (!containers[ev._sday]) {
          containers[ev._sday] = {
            real: parent,
            buffer: document.createDocumentFragment(),
            width: parent.clientWidth
          };
        }

        var container = containers[ev._sday];
        this.render_event(ev, container.buffer, container.width);
      }
    }

    for (var i in containers) {
      var container = containers[i];

      if (container.real && container.buffer) {
        container.real.appendChild(container.buffer);
      }
    }
  };

  scheduler._get_first_visible_cell = function (cells) {
    for (var i = 0; i < cells.length; i++) {
      if ((cells[i].className || "").indexOf("dhx_scale_ignore") == -1) {
        return cells[i];
      }
    } // if no visible cell found, return cells[0] to be more tolerant, since it's the original logic


    return cells[0];
  };

  scheduler._pre_render_events = function (evs, hold) {
    var hb = this.xy.bar_height;
    var h_old = this._colsS.heights;
    var h = this._colsS.heights = [0, 0, 0, 0, 0, 0, 0];
    var data = this._els["dhx_cal_data"][0];

    if (!this._table_view) {
      evs = this._pre_render_events_line(evs, hold); //ignore long events for now
    } else {
      evs = this._pre_render_events_table(evs, hold);
    }

    if (this._table_view) {
      if (hold) this._colsS.heights = h_old;else {
        var evl = data.firstChild;

        if (evl.rows) {
          for (var i = 0; i < evl.rows.length; i++) {
            h[i]++;
            var cells = evl.rows[i].cells;
            var cellHeight = this._colsS.height - this.xy.month_head_height;

            if (h[i] * hb > cellHeight) {
              // 22 - height of cell's header
              //we have overflow, update heights
              var cHeight = cellHeight;

              if (this.config.max_month_events * 1 !== this.config.max_month_events || h[i] <= this.config.max_month_events) {
                cHeight = h[i] * hb;
              } else if ((this.config.max_month_events + 1) * hb > cellHeight) {
                cHeight = (this.config.max_month_events + 1) * hb;
              }

              for (var j = 0; j < cells.length; j++) {
                cells[j].childNodes[1].style.height = cHeight + "px";
              } //	h[i] = (h[i - 1] || 0) + cells[0].offsetHeight;

            }

            h[i] = (h[i - 1] || 0) + scheduler._get_first_visible_cell(cells).offsetHeight;
          }

          h.unshift(0);

          if (evl.parentNode.offsetHeight < evl.parentNode.scrollHeight && !scheduler._colsS.scroll_fix && scheduler.xy.scroll_width) {
            var scale_settings = scheduler._colsS,
                sum_width = scale_settings[scale_settings.col_length],
                row_heights = scale_settings.heights.slice();
            sum_width -= scheduler.xy.scroll_width || 0;

            this._calc_scale_sizes(sum_width, this._min_date, this._max_date);

            scheduler._colsS.heights = row_heights;
            this.set_xy(this._els["dhx_cal_header"][0], sum_width, this.xy.scale_height);

            scheduler._render_scales(this._els["dhx_cal_header"][0]);

            scheduler._render_month_scale(this._els["dhx_cal_data"][0], this._get_timeunit_start(), this._min_date);

            scale_settings.scroll_fix = true;
          }
        } else {
          if (!evs.length && this._els["dhx_multi_day"][0].style.visibility == "visible") h[0] = -1;

          if (evs.length || h[0] == -1) {
            //shift days to have space for multiday events
            //var childs = evl.parentNode.childNodes;
            // +1 so multiday events would have 2px from top and 2px from bottom by default
            var full_multi_day_height = (h[0] + 1) * hb + 1;
            var used_multi_day_height = full_multi_day_height;
            var used_multi_day_height_css = full_multi_day_height + "px";

            if (this.config.multi_day_height_limit) {
              used_multi_day_height = Math.min(full_multi_day_height, this.config.multi_day_height_limit);
              used_multi_day_height_css = used_multi_day_height + "px";
            }

            data.style.top = this._els["dhx_cal_navline"][0].offsetHeight + this._els["dhx_cal_header"][0].offsetHeight + used_multi_day_height + 'px';
            data.style.height = this._obj.offsetHeight - parseInt(data.style.top, 10) - (this.xy.margin_top || 0) + 'px';
            var multi_day_section = this._els["dhx_multi_day"][0];
            multi_day_section.style.height = used_multi_day_height_css;
            multi_day_section.style.visibility = h[0] == -1 ? "hidden" : "visible"; // icon

            var multi_day_icon = this._els["dhx_multi_day"][1];
            multi_day_icon.style.height = used_multi_day_height_css;
            multi_day_icon.style.visibility = h[0] == -1 ? "hidden" : "visible";
            multi_day_icon.className = h[0] ? "dhx_multi_day_icon" : "dhx_multi_day_icon_small";
            this._dy_shift = (h[0] + 1) * hb;

            if (this.config.multi_day_height_limit) {
              this._dy_shift = Math.min(this.config.multi_day_height_limit, this._dy_shift);
            }

            h[0] = 0;

            if (used_multi_day_height != full_multi_day_height) {
              data.style.top = parseInt(data.style.top) + 2 + "px";
              multi_day_section.style.overflowY = "auto"; //	multi_day_section.style.width = (parseInt(this._els["dhx_cal_navline"][0].style.width)) + "px";

              multi_day_icon.style.position = "fixed";
              multi_day_icon.style.top = "";
              multi_day_icon.style.left = "";
            }
          }
        }
      }
    }

    return evs;
  };

  scheduler._get_event_sday = function (ev) {
    // get day in current view
    // use rounding for 23 or 25 hour days on DST
    var datePart = this.date.day_start(new Date(ev.start_date));
    return Math.round((datePart.valueOf() - this._min_date.valueOf()) / (24 * 60 * 60 * 1000));
  };

  scheduler._get_event_mapped_end_date = function (ev) {
    var end_date = ev.end_date;

    if (this.config.separate_short_events) {
      var ev_duration = (ev.end_date - ev.start_date) / 60000; // minutes

      if (ev_duration < this._min_mapped_duration) {
        end_date = this.date.add(end_date, this._min_mapped_duration - ev_duration, "minute");
      }
    }

    return end_date;
  };

  scheduler._pre_render_events_line = function (evs, hold) {
    evs.sort(function (a, b) {
      if (a.start_date.valueOf() == b.start_date.valueOf()) return a.id > b.id ? 1 : -1;
      return a.start_date > b.start_date ? 1 : -1;
    });
    var days = []; //events by weeks

    var evs_originals = [];
    this._min_mapped_duration = Math.ceil(this.xy.min_event_height * 60 / this.config.hour_size_px); // values could change along the way

    for (var i = 0; i < evs.length; i++) {
      var ev = evs[i]; //check date overflow

      var sd = ev.start_date;
      var ed = ev.end_date; //check scale overflow

      var sh = sd.getHours();
      var eh = ed.getHours();
      ev._sday = this._get_event_sday(ev); // sday based on event start_date

      if (this._ignores[ev._sday]) {
        //ignore event
        evs.splice(i, 1);
        i--;
        continue;
      }

      if (!days[ev._sday]) days[ev._sday] = [];

      if (!hold) {
        ev._inner = false;
        var stack = days[ev._sday];

        while (stack.length) {
          var t_ev = stack[stack.length - 1];

          var t_end_date = this._get_event_mapped_end_date(t_ev);

          if (t_end_date.valueOf() <= ev.start_date.valueOf()) {
            stack.splice(stack.length - 1, 1);
          } else {
            break;
          }
        }

        var slot_index = stack.length;
        var sorderSet = false;

        for (var j = 0; j < stack.length; j++) {
          var t_ev = stack[j];

          var t_end_date = this._get_event_mapped_end_date(t_ev);

          if (t_end_date.valueOf() <= ev.start_date.valueOf()) {
            sorderSet = true;
            ev._sorder = t_ev._sorder;
            slot_index = j;
            ev._inner = true;
            break;
          }
        }

        if (stack.length) stack[stack.length - 1]._inner = true;

        if (!sorderSet) {
          if (stack.length) {
            if (stack.length <= stack[stack.length - 1]._sorder) {
              if (!stack[stack.length - 1]._sorder) ev._sorder = 0;else for (j = 0; j < stack.length; j++) {
                var _is_sorder = false;

                for (var k = 0; k < stack.length; k++) {
                  if (stack[k]._sorder == j) {
                    _is_sorder = true;
                    break;
                  }
                }

                if (!_is_sorder) {
                  ev._sorder = j;
                  break;
                }
              }
              ev._inner = true;
            } else {
              var _max_sorder = stack[0]._sorder;

              for (j = 1; j < stack.length; j++) {
                if (stack[j]._sorder > _max_sorder) _max_sorder = stack[j]._sorder;
              }

              ev._sorder = _max_sorder + 1;
              ev._inner = false;
            }
          } else ev._sorder = 0;
        }

        stack.splice(slot_index, slot_index == stack.length ? 0 : 1, ev);

        if (stack.length > (stack.max_count || 0)) {
          stack.max_count = stack.length;
          ev._count = stack.length;
        } else {
          ev._count = ev._count ? ev._count : 1;
        }
      }

      if (sh < this.config.first_hour || eh >= this.config.last_hour) {
        // Need to create copy of event as we will be changing it's start/end date
        // e.g. first_hour = 11 and event.start_date hours = 9. Need to preserve that info
        evs_originals.push(ev);
        evs[i] = ev = this._copy_event(ev);

        if (sh < this.config.first_hour) {
          ev.start_date.setHours(this.config.first_hour);
          ev.start_date.setMinutes(0);
        }

        if (eh >= this.config.last_hour) {
          ev.end_date.setMinutes(0);
          ev.end_date.setHours(this.config.last_hour);
        }

        if (ev.start_date > ev.end_date || sh == this.config.last_hour) {
          evs.splice(i, 1);
          i--;
          continue;
        }
      }
    }

    if (!hold) {
      for (var i = 0; i < evs.length; i++) {
        evs[i]._count = days[evs[i]._sday].max_count;
      }

      for (var i = 0; i < evs_originals.length; i++) {
        evs_originals[i]._count = days[evs_originals[i]._sday].max_count;
      }
    }

    return evs;
  };

  scheduler._time_order = function (evs) {
    evs.sort(function (a, b) {
      if (a.start_date.valueOf() == b.start_date.valueOf()) {
        if (a._timed && !b._timed) return 1;
        if (!a._timed && b._timed) return -1;
        return a.id > b.id ? 1 : -1;
      }

      return a.start_date > b.start_date ? 1 : -1;
    });
  };

  scheduler._is_any_multiday_cell_visible = function (from, to, event) {
    var cols = this._cols.length;
    var isAnyCellVisible = false;
    var checkDate = from;
    var noCells = true;
    var lastDayEnd = new Date(to);

    if (scheduler.date.day_start(new Date(to)).valueOf() != to.valueOf()) {
      lastDayEnd = scheduler.date.day_start(lastDayEnd);
      lastDayEnd = scheduler.date.add(lastDayEnd, 1, "day");
    }

    while (checkDate < lastDayEnd) {
      noCells = false;
      var cellIndex = this.locate_holder_day(checkDate, false, event);
      var weekCellIndex = cellIndex % cols;

      if (!this._ignores[weekCellIndex]) {
        isAnyCellVisible = true;
        break;
      }

      checkDate = scheduler.date.add(checkDate, 1, "day");
    }

    return noCells || isAnyCellVisible;
  };

  scheduler._pre_render_events_table = function (evs, hold) {
    // max - max height of week slot
    this._time_order(evs);

    var out = [];
    var weeks = [[], [], [], [], [], [], []]; //events by weeks

    var max = this._colsS.heights;
    var start_date;
    var cols = this._cols.length;
    var chunks_info = {};

    for (var i = 0; i < evs.length; i++) {
      var ev = evs[i];
      var id = ev.id;

      if (!chunks_info[id]) {
        chunks_info[id] = {
          first_chunk: true,
          last_chunk: true
        };
      }

      var chunk_info = chunks_info[id];
      var sd = start_date || ev.start_date;
      var ed = ev.end_date; //trim events which are crossing through current view

      if (sd < this._min_date) {
        chunk_info.first_chunk = false;
        sd = this._min_date;
      }

      if (ed > this._max_date) {
        chunk_info.last_chunk = false;
        ed = this._max_date;
      }

      var locate_s = this.locate_holder_day(sd, false, ev);
      ev._sday = locate_s % cols; //skip single day events for ignored dates

      if (this._ignores[ev._sday] && ev._timed) continue;
      var locate_e = this.locate_holder_day(ed, true, ev) || cols;
      ev._eday = locate_e % cols || cols; //cols used to fill full week, when event end on monday

      ev._length = locate_e - locate_s; //3600000 - compensate 1 hour during winter|summer time shift

      ev._sweek = Math.floor((this._correct_shift(sd.valueOf(), 1) - this._min_date.valueOf()) / (60 * 60 * 1000 * 24 * cols));

      var isAnyCellVisible = scheduler._is_any_multiday_cell_visible(sd, ed, ev);

      if (!isAnyCellVisible) {
        continue;
      } //current slot


      var stack = weeks[ev._sweek]; //check order position

      var stack_line;

      for (stack_line = 0; stack_line < stack.length; stack_line++) {
        if (stack[stack_line]._eday <= ev._sday) break;
      }

      if (!ev._sorder || !hold) {
        ev._sorder = stack_line;
      }

      if (ev._sday + ev._length <= cols) {
        start_date = null;
        out.push(ev);
        stack[stack_line] = ev; //get max height of slot

        max[ev._sweek] = stack.length - 1;
        ev._first_chunk = chunk_info.first_chunk;
        ev._last_chunk = chunk_info.last_chunk;
      } else {
        // split long event in chunks
        var copy = this._copy_event(ev);

        copy.id = ev.id;
        copy._length = cols - ev._sday;
        copy._eday = cols;
        copy._sday = ev._sday;
        copy._sweek = ev._sweek;
        copy._sorder = ev._sorder;
        copy.end_date = this.date.add(sd, copy._length, "day");
        copy._first_chunk = chunk_info.first_chunk;

        if (chunk_info.first_chunk) {
          chunk_info.first_chunk = false;
        }

        out.push(copy);
        stack[stack_line] = copy;
        start_date = copy.end_date; //get max height of slot

        max[ev._sweek] = stack.length - 1;
        i--;
        continue; //repeat same step
      }
    }

    return out;
  };

  scheduler._copy_dummy = function () {
    var a = new Date(this.start_date);
    var b = new Date(this.end_date);
    this.start_date = a;
    this.end_date = b;
  };

  scheduler._copy_event = function (ev) {
    this._copy_dummy.prototype = ev;
    return new this._copy_dummy(); //return {start_date:ev.start_date, end_date:ev.end_date, text:ev.text, id:ev.id}
  };

  scheduler._rendered = [];

  scheduler.clear_view = function () {
    for (var i = 0; i < this._rendered.length; i++) {
      var obj = this._rendered[i];
      if (obj.parentNode) obj.parentNode.removeChild(obj);
    }

    this._rendered = [];
  };

  scheduler.updateEvent = function (id) {
    var ev = this.getEvent(id);
    this.clear_event(id);

    if (ev && this.is_visible_events(ev) && this.filter_event(id, ev) && (this._table_view || this.config.multi_day || ev._timed)) {
      if (this.config.update_render) {
        this.render_view_data();
      } else {
        if (this.getState().mode == "month" && !this.getState().drag_id && !this.isOneDayEvent(ev)) {
          this.render_view_data();
        } else {
          this.render_view_data([ev], true);
        }
      }
    }
  };

  scheduler.clear_event = function (id) {
    this.for_rendered(id, function (node, i) {
      if (node.parentNode) node.parentNode.removeChild(node);

      scheduler._rendered.splice(i, 1);
    });
  };

  scheduler._y_from_date = function (date) {
    var sm = date.getHours() * 60 + date.getMinutes();
    return Math.round((sm * 60 * 1000 - this.config.first_hour * 60 * 60 * 1000) * this.config.hour_size_px / (60 * 60 * 1000)) % (this.config.hour_size_px * 24); //42px/hour
  };

  scheduler._calc_event_y = function (ev, min_height) {
    min_height = min_height || 0;
    var sm = ev.start_date.getHours() * 60 + ev.start_date.getMinutes();
    var em = ev.end_date.getHours() * 60 + ev.end_date.getMinutes() || scheduler.config.last_hour * 60;

    var top = this._y_from_date(ev.start_date);

    var height = Math.max(min_height, (em - sm) * this.config.hour_size_px / 60); //42px/hour

    return {
      top: top,
      height: height
    };
  };

  scheduler.render_event = function (ev, buffer, parentWidth) {
    var menu = scheduler.xy.menu_width;
    var menu_offset = this.config.use_select_menu_space ? 0 : menu;
    if (ev._sday < 0) return; //can occur in case of recurring event during time shift

    var parent = scheduler.locate_holder(ev._sday);
    if (!parent) return; //attempt to render non-visible event

    buffer = buffer || parent;

    var pos_y = this._calc_event_y(ev, scheduler.xy.min_event_height);

    var top = pos_y.top,
        height = pos_y.height;
    var ev_count = ev._count || 1;
    var ev_sorder = ev._sorder || 0;
    parentWidth = parentWidth || parent.clientWidth;
    var width = Math.floor((parentWidth - menu_offset) / ev_count);
    var left = ev_sorder * width + 1;
    if (!ev._inner) width = width * (ev_count - ev_sorder);

    if (this.config.cascade_event_display) {
      var limit = this.config.cascade_event_count;
      var margin = this.config.cascade_event_margin;
      left = ev_sorder % limit * margin;
      var right = ev._inner ? (ev_count - ev_sorder - 1) % limit * margin / 2 : 0;
      width = Math.floor(parentWidth - menu_offset - left - right);
    }

    var d = this._render_v_bar(ev, menu_offset + left, top, width, height, ev._text_style, scheduler.templates.event_header(ev.start_date, ev.end_date, ev), scheduler.templates.event_text(ev.start_date, ev.end_date, ev));

    this._waiAria.eventAttr(ev, d);

    this._rendered.push(d);

    buffer.appendChild(d);
    var parentPosition = parseInt(this.config.rtl ? parent.style.right : parent.style.left, 10);
    left = left + parentPosition + menu_offset;

    if (this._edit_id == ev.id) {
      d.style.zIndex = 1; //fix overlapping issue

      width = Math.max(width - 4, scheduler.xy.editor_width);
      d = document.createElement("div");
      d.setAttribute("event_id", ev.id); // for backward compatibility

      d.setAttribute(this.config.event_attribute, ev.id);

      this._waiAria.eventAttr(ev, d);

      d.className = "dhx_cal_event dhx_cal_editor";
      if (this.config.rtl) left++;
      this.set_xy(d, width, height - 20, left, top + (scheduler.xy.event_header_height || 14));

      if (ev.color) {
        d.style.backgroundColor = ev.color;
      }

      var tplClass = scheduler.templates.event_class(ev.start_date, ev.end_date, ev);

      if (tplClass) {
        d.className += " " + tplClass;
      }

      var d2 = document.createElement("div");
      this.set_xy(d2, width - 6, height - 26);
      d2.style.cssText += ";margin:2px 2px 2px 2px;overflow:hidden;";
      d.appendChild(d2);

      this._els["dhx_cal_data"][0].appendChild(d);

      this._rendered.push(d);

      d2.innerHTML = "<textarea class='dhx_cal_editor'>" + ev.text + "</textarea>";
      this._editor = d2.querySelector("textarea");
      if (this._quirks7) this._editor.style.height = height - 12 + "px"; //IEFIX

      this._editor.onkeydown = function (e) {
        if (e.shiftKey) return true;
        var code = e.keyCode;
        if (code == scheduler.keys.edit_save) scheduler.editStop(true);
        if (code == scheduler.keys.edit_cancel) scheduler.editStop(false);

        if (code == scheduler.keys.edit_save || code == scheduler.keys.edit_cancel) {
          if (e.preventDefault) e.preventDefault();
        }
      };

      this._editor.onselectstart = function (e) {
        e.cancelBubble = true;
        return true;
      };

      scheduler._focus(this._editor, true); //IE and opera can add x-scroll during focusing


      this._els["dhx_cal_data"][0].scrollLeft = 0;
    }

    if (this.xy.menu_width !== 0 && this._select_id == ev.id) {
      if (this.config.cascade_event_display && this._drag_mode) d.style.zIndex = 1; //fix overlapping issue for cascade view in case of dnd of selected event

      var icons = this.config["icons_" + (this._edit_id == ev.id ? "edit" : "select")];
      var icons_str = "";
      var bg_color = ev.color ? "background-color: " + ev.color + ";" : "";
      var color = ev.textColor ? "color: " + ev.textColor + ";" : "";
      var ariaAttr;

      for (var i = 0; i < icons.length; i++) {
        ariaAttr = this._waiAria.eventMenuAttrString(icons[i]);
        icons_str += "<div class='dhx_menu_icon " + icons[i] + "' style='" + bg_color + "" + color + "' title='" + this.locale.labels[icons[i]] + "'" + ariaAttr + "></div>";
      }

      var obj = this._render_v_bar(ev, left - menu + 1, top, menu, icons.length * 20 + 26 - 2, "", "<div style='" + bg_color + "" + color + "' class='dhx_menu_head'></div>", icons_str, true);

      obj.style.left = left - menu + 1;

      this._els["dhx_cal_data"][0].appendChild(obj);

      this._rendered.push(obj);
    }

    if (this.config.drag_highlight && this._drag_id == ev.id) {
      this.highlightEventPosition(ev);
    }
  };

  scheduler._render_v_bar = function (ev, x, y, w, h, style, contentA, contentB, bottom) {
    var d = document.createElement("div");
    var id = ev.id;
    var cs = bottom ? "dhx_cal_event dhx_cal_select_menu" : "dhx_cal_event";
    var state = scheduler.getState();

    if (state.drag_id == ev.id) {
      cs += " dhx_cal_event_drag";
    }

    if (state.select_id == ev.id) {
      cs += " dhx_cal_event_selected";
    }

    var cse = scheduler.templates.event_class(ev.start_date, ev.end_date, ev);
    if (cse) cs = cs + " " + cse;

    if (this.config.cascade_event_display) {
      cs += " dhx_cal_event_cascade";
    }

    var bg_color = ev.color ? "background-color:" + ev.color + ";" : "";
    var color = ev.textColor ? "color:" + ev.textColor + ";" : "";

    var borderBox = scheduler._border_box_events();

    var borderBoxWidth = w - 2;
    var boxWidth = borderBox ? borderBoxWidth : w - 4,
        headerWidth = borderBox ? borderBoxWidth : w - 6,
        bodyWidth = borderBox ? borderBoxWidth : w - (this._quirks ? 4 : 14),
        footerWidth = borderBox ? borderBoxWidth - 2 : w - 8;
    var bodyHeight = borderBox ? h - this.xy.event_header_height - 1 : h - (this._quirks ? 20 : 30) + 1;
    var html = '<div event_id="' + id + '" ' + this.config.event_attribute + '="' + id + '" class="' + cs + '" style="position:absolute; top:' + y + 'px; ' + (this.config.rtl ? 'right:' : 'left:') + x + 'px; width:' + boxWidth + 'px; height:' + h + 'px;' + (style || "") + '"></div>';
    d.innerHTML = html;
    var container = d.cloneNode(true).firstChild;

    if (!bottom && scheduler.renderEvent(container, ev, w, h, contentA, contentB)) {
      return container;
    } else {
      container = d.firstChild;
      var inner_html = '<div class="dhx_event_move dhx_header" style=" width:' + headerWidth + 'px;' + bg_color + '" >&nbsp;</div>';
      inner_html += '<div class="dhx_event_move dhx_title" style="' + bg_color + '' + color + '">' + contentA + '</div>';
      inner_html += '<div class="dhx_body" style=" width:' + bodyWidth + 'px; height:' + bodyHeight + 'px;' + bg_color + '' + color + '">' + contentB + '</div>'; // +2 css specific, moved from render_event

      var footer_class = "dhx_event_resize dhx_footer";
      if (bottom || ev._drag_resize === false) footer_class = "dhx_resize_denied " + footer_class;
      inner_html += '<div class="' + footer_class + '" style=" width:' + footerWidth + 'px;' + (bottom ? ' margin-top:-1px;' : '') + '' + bg_color + '' + color + '" ></div>';
      container.innerHTML = inner_html;
    }

    return container;
  };

  scheduler.renderEvent = function () {
    return false;
  };

  scheduler.locate_holder = function (day) {
    if (this._mode == "day") return this._els["dhx_cal_data"][0].firstChild; //dirty

    return this._els["dhx_cal_data"][0].childNodes[day];
  };

  scheduler.locate_holder_day = function (date, past) {
    var day = Math.floor((this._correct_shift(date, 1) - this._min_date) / (60 * 60 * 24 * 1000)); //when locating end data of event , we need to use next day if time part was defined

    if (past && this.date.time_part(date)) day++;
    return day;
  };

  scheduler._get_dnd_order = function (order, ev_height, max_height) {
    if (!this._drag_event) return order;
    if (!this._drag_event._orig_sorder) this._drag_event._orig_sorder = order;else order = this._drag_event._orig_sorder;
    var evTop = ev_height * order;

    while (evTop + ev_height > max_height) {
      order--;
      evTop -= ev_height;
    }

    order = Math.max(order, 0);
    return order;
  }; //scheduler._get_event_bar_pos = function(sday, eday, week, drag){


  scheduler._get_event_bar_pos = function (ev) {
    var rtl = this.config.rtl;
    var columns = this._colsS;
    var x = columns[ev._sday];
    var x2 = columns[ev._eday];

    if (rtl) {
      x = columns[columns.col_length] - columns[ev._eday] + columns[0];
      x2 = columns[columns.col_length] - columns[ev._sday] + columns[0];
    }

    if (x2 == x) x2 = columns[ev._eday + 1];
    var hb = this.xy.bar_height;
    var order = ev._sorder;

    if (ev.id == this._drag_id) {
      var cellHeight = columns.heights[ev._sweek + 1] - columns.heights[ev._sweek] - this.xy.month_head_height; //22 for month head height

      order = scheduler._get_dnd_order(order, hb, cellHeight);
    }

    var y_event_offset = order * hb;
    var y = columns.heights[ev._sweek] + (columns.height ? this.xy.month_scale_height + 2 : 2) + y_event_offset;
    return {
      x: x,
      x2: x2,
      y: y
    };
  };

  scheduler.render_event_bar = function (ev) {
    var parent = this._rendered_location;

    var pos = this._get_event_bar_pos(ev);

    var y = pos.y;
    var x = pos.x;
    var x2 = pos.x2; // resize for month mutliday events

    var resize_handle = ""; //events in ignored dates

    if (!x2) return;
    var resizable = scheduler.config.resize_month_events && this._mode == "month" && (!ev._timed || scheduler.config.resize_month_timed);
    var d = document.createElement("div");

    var left_chunk = ev.hasOwnProperty("_first_chunk") && ev._first_chunk,
        right_chunk = ev.hasOwnProperty("_last_chunk") && ev._last_chunk;

    var resize_left = resizable && (ev._timed || left_chunk);
    var resize_right = resizable && (ev._timed || right_chunk);
    var timed = true;
    var cs = "dhx_cal_event_clear";

    if (!ev._timed || resizable) {
      timed = false;
      cs = "dhx_cal_event_line";
    }

    if (left_chunk) {
      cs += " dhx_cal_event_line_start";
    }

    if (right_chunk) {
      cs += " dhx_cal_event_line_end";
    }

    if (resize_left) {
      resize_handle += "<div class='dhx_event_resize dhx_event_resize_start'></div>";
    }

    if (resize_right) {
      resize_handle += "<div class='dhx_event_resize dhx_event_resize_end'></div>";
    }

    var cse = scheduler.templates.event_class(ev.start_date, ev.end_date, ev);

    if (cse) {
      cs += " " + cse;
    }

    var bg_color = ev.color ? "background:" + ev.color + ";" : "";
    var color = ev.textColor ? "color:" + ev.textColor + ";" : "";
    var style_text = ["position:absolute", "top:" + y + "px", "left:" + x + "px", "width:" + (x2 - x - 3 - (timed ? 1 : 0)) + "px", color, bg_color, ev._text_style || ""].join(";");
    var html = "<div event_id='" + ev.id + "' " + this.config.event_attribute + "='" + ev.id + "' class='" + cs + "' style='" + style_text + "'" + this._waiAria.eventBarAttrString(ev) + ">";

    if (resizable) {
      html += resize_handle;
    }

    if (scheduler.getState().mode == "month") {
      ev = scheduler.getEvent(ev.id); // ev at this point could be a part (row in a month view) of a larger event
    }

    if (ev._timed) html += scheduler.templates.event_bar_date(ev.start_date, ev.end_date, ev);
    html += scheduler.templates.event_bar_text(ev.start_date, ev.end_date, ev) + '</div>';
    html += '</div>';
    d.innerHTML = html;

    this._rendered.push(d.firstChild);

    parent.appendChild(d.firstChild);
  };

  scheduler._locate_event = function (node) {
    var id = null;

    while (node && !id && node.getAttribute) {
      id = node.getAttribute(this.config.event_attribute);
      node = node.parentNode;
    }

    return id;
  };

  scheduler.edit = function (id) {
    if (this._edit_id == id) return;
    this.editStop(false, id);
    this._edit_id = id;
    this.updateEvent(id);
  };

  scheduler.editStop = function (mode, id) {
    if (id && this._edit_id == id) return;
    var ev = this.getEvent(this._edit_id);

    if (ev) {
      if (mode) ev.text = this._editor.value;
      this._edit_id = null;
      this._editor = null;
      this.updateEvent(ev.id);

      this._edit_stop_event(ev, mode);
    }
  };

  scheduler._edit_stop_event = function (ev, mode) {
    if (this._new_event) {
      if (!mode) {
        if (ev) // in case of custom lightbox user can already delete event
          this.deleteEvent(ev.id, true);
      } else {
        this.callEvent("onEventAdded", [ev.id, ev]);
      }

      this._new_event = null;
    } else {
      if (mode) {
        this.callEvent("onEventChanged", [ev.id, ev]);
      }
    }
  };

  scheduler.getEvents = function (from, to) {
    var result = [];

    for (var a in this._events) {
      var ev = this._events[a];
      if (ev && (!from && !to || ev.start_date < to && ev.end_date > from)) result.push(ev);
    }

    return result;
  };

  scheduler.getRenderedEvent = function (id) {
    if (!id) return;
    var rendered_events = scheduler._rendered;

    for (var i = 0; i < rendered_events.length; i++) {
      var rendered_event = rendered_events[i];

      if (rendered_event.getAttribute(scheduler.config.event_attribute) == id) {
        return rendered_event;
      }
    }

    return null;
  };

  scheduler.showEvent = function (id, mode) {
    var section;

    if (id && _typeof(id) === "object") {
      mode = id.mode;
      section = id.section;
      id = id.section;
    }

    var ev = typeof id == "number" || typeof id == "string" ? scheduler.getEvent(id) : id;
    mode = mode || scheduler._mode;
    if (!ev || this.checkEvent("onBeforeEventDisplay") && !this.callEvent("onBeforeEventDisplay", [ev, mode])) return;
    var scroll_hour = scheduler.config.scroll_hour;
    scheduler.config.scroll_hour = ev.start_date.getHours();
    var preserve_scroll = scheduler.config.preserve_scroll;
    scheduler.config.preserve_scroll = false;
    var original_color = ev.color;
    var original_text_color = ev.textColor;

    if (scheduler.config.highlight_displayed_event) {
      ev.color = scheduler.config.displayed_event_color;
      ev.textColor = scheduler.config.displayed_event_text_color;
    }

    scheduler.setCurrentView(new Date(ev.start_date), mode);

    function restoreOriginalColors() {
      ev.color = original_color;
      ev.textColor = original_text_color;
    }

    scheduler.config.scroll_hour = scroll_hour;
    scheduler.config.preserve_scroll = preserve_scroll;

    if (scheduler.matrix && scheduler.matrix[mode]) {
      var timeline = scheduler.getView();
      var property = timeline.y_property;
      var event = scheduler.getEvent(ev.id);

      if (event) {
        if (!section) {
          var section = event[property];

          if (Array.isArray(section)) {
            section = section[0];
          } else if (typeof section === "string" && scheduler.config.section_delimiter && section.indexOf(scheduler.config.section_delimiter) > -1) {
            section = section.split(scheduler.config.section_delimiter)[0];
          }
        }

        var top = timeline.getSectionTop(section);
        var left = timeline.posFromDate(event.start_date);
        var container = scheduler.$container.querySelector(".dhx_timeline_data_wrapper");
        left = left - (container.offsetWidth - timeline.dx) / 2;
        top = top - container.offsetHeight / 2 + timeline.dy / 2;

        if (timeline._smartRenderingEnabled()) {
          var handlerId = timeline.attachEvent("onScroll", function () {
            restoreOriginalColors();
            timeline.detachEvent(handlerId);
          });
        }

        timeline.scrollTo({
          left: left,
          top: top
        });

        if (!timeline._smartRenderingEnabled()) {
          restoreOriginalColors();
        }
      }
    } else {
      restoreOriginalColors();
    }

    scheduler.callEvent("onAfterEventDisplay", [ev, mode]);
  };
}