export default function extend(scheduler) {
  scheduler._lightbox_controls = {};

  scheduler.formSection = function (name) {
    var config = this.config.lightbox.sections;
    var i = 0;

    for (i; i < config.length; i++) {
      if (config[i].name == name) {
        break;
      }
    }

    if (i === config.length) {
      // GS-1662 section not found, should exit here instead of throwing an error
      return null;
    }

    var section = config[i];

    if (!scheduler._lightbox) {
      scheduler.getLightbox();
    }

    var header = document.getElementById(section.id);
    var node = header.nextSibling;
    var result = {
      section: section,
      header: header,
      node: node,
      getValue: function getValue(ev) {
        return scheduler.form_blocks[section.type].get_value(node, ev || {}, section);
      },
      setValue: function setValue(value, ev) {
        return scheduler.form_blocks[section.type].set_value(node, value, ev || {}, section);
      }
    };
    var handler = scheduler._lightbox_controls["get_" + section.type + "_control"];
    return handler ? handler(result) : result;
  };

  scheduler._lightbox_controls.get_template_control = function (result) {
    result.control = result.node;
    return result;
  };

  scheduler._lightbox_controls.get_select_control = function (result) {
    result.control = result.node.getElementsByTagName('select')[0];
    return result;
  };

  scheduler._lightbox_controls.get_textarea_control = function (result) {
    result.control = result.node.getElementsByTagName('textarea')[0];
    return result;
  };

  scheduler._lightbox_controls.get_time_control = function (result) {
    result.control = result.node.getElementsByTagName('select'); // array

    return result;
  };

  scheduler._lightbox_controls.defaults = {
    template: {
      height: 30
    },
    textarea: {
      height: 200
    },
    select: {
      height: 23
    },
    time: {
      height: 20
    }
  };
  scheduler.form_blocks = {
    template: {
      render: function render(sns) {
        var defaults = scheduler._lightbox_controls.defaults.template;
        var defaultHeight = defaults ? defaults.height : 30;
        var height = (sns.height || defaultHeight || 30) + "px";
        return "<div class='dhx_cal_ltext dhx_cal_template' style='height:" + height + ";'></div>";
      },
      set_value: function set_value(node, value, ev, config) {
        node.innerHTML = value || "";
      },
      get_value: function get_value(node, ev, config) {
        return node.innerHTML || "";
      },
      focus: function focus(node) {}
    },
    textarea: {
      render: function render(sns) {
        var defaults = scheduler._lightbox_controls.defaults.textarea;
        var defaultHeight = defaults ? defaults.height : 200;
        var height = (sns.height || defaultHeight || "130") + "px";
        return "<div class='dhx_cal_ltext' style='height:" + height + ";'><textarea></textarea></div>";
      },
      set_value: function set_value(node, value, ev) {
        scheduler.form_blocks.textarea._get_input(node).value = value || "";
      },
      get_value: function get_value(node, ev) {
        return scheduler.form_blocks.textarea._get_input(node).value;
      },
      focus: function focus(node) {
        var a = scheduler.form_blocks.textarea._get_input(node);

        scheduler._focus(a, true);
      },
      _get_input: function _get_input(node) {
        return node.getElementsByTagName("textarea")[0];
      }
    },
    select: {
      render: function render(sns) {
        var defaults = scheduler._lightbox_controls.defaults.select;
        var defaultHeight = defaults ? defaults.height : 23;
        var height = (sns.height || defaultHeight || "23") + "px";
        var html = "<div class='dhx_cal_ltext' style='height:" + height + ";'><select style='width:100%;'>";

        for (var i = 0; i < sns.options.length; i++) {
          html += "<option value='" + sns.options[i].key + "'>" + sns.options[i].label + "</option>";
        }

        html += "</select></div>";
        return html;
      },
      set_value: function set_value(node, value, ev, sns) {
        var select = node.firstChild;

        if (!select._dhx_onchange && sns.onchange) {
          select.onchange = sns.onchange;
          select._dhx_onchange = true;
        }

        if (typeof value == "undefined") value = (select.options[0] || {}).value;
        select.value = value || "";
      },
      get_value: function get_value(node, ev) {
        return node.firstChild.value;
      },
      focus: function focus(node) {
        var a = node.firstChild;

        scheduler._focus(a, true);
      }
    },
    time: {
      render: function render(sns) {
        if (!sns.time_format) {
          // default order
          sns.time_format = ["%H:%i", "%d", "%m", "%Y"];
        } // map: default order => real one


        sns._time_format_order = {};
        var time_format = sns.time_format;
        var cfg = scheduler.config;
        var dt = scheduler.date.date_part(scheduler._currentDate());
        var last = 24 * 60,
            first = 0;

        if (scheduler.config.limit_time_select) {
          last = 60 * cfg.last_hour + 1;
          first = 60 * cfg.first_hour;
          dt.setHours(cfg.first_hour);
        }

        var html = "";

        for (var p = 0; p < time_format.length; p++) {
          var time_option = time_format[p]; // adding spaces between selects

          if (p > 0) {
            html += " ";
          }

          var selectBoxClass = "";
          var options = "";

          switch (time_option) {
            case "%Y":
              selectBoxClass = "dhx_lightbox_year_select";
              sns._time_format_order[3] = p; //year

              var range;
              var start_year;
              var end_year;

              if (sns.year_range) {
                if (!isNaN(sns.year_range)) {
                  range = sns.year_range;
                } else if (sns.year_range.push) {
                  // if
                  start_year = sns.year_range[0];
                  end_year = sns.year_range[1];
                }
              }

              range = range || 10;
              var offset = offset || Math.floor(range / 2);
              start_year = start_year || dt.getFullYear() - offset;
              end_year = end_year || start_year + range;

              for (var i = start_year; i < end_year; i++) {
                options += "<option value='" + i + "'>" + i + "</option>";
              }

              break;

            case "%m":
              selectBoxClass = "dhx_lightbox_month_select";
              sns._time_format_order[2] = p; //month

              for (var i = 0; i < 12; i++) {
                options += "<option value='" + i + "'>" + this.locale.date.month_full[i] + "</option>";
              }

              break;

            case "%d":
              selectBoxClass = "dhx_lightbox_day_select";
              sns._time_format_order[1] = p; //days

              for (var i = 1; i < 32; i++) {
                options += "<option value='" + i + "'>" + i + "</option>";
              }

              break;

            case "%H:%i":
              selectBoxClass = "dhx_lightbox_time_select";
              sns._time_format_order[0] = p; //hours

              var i = first;
              var tdate = dt.getDate();
              sns._time_values = [];

              while (i < last) {
                var time = this.templates.time_picker(dt);
                options += "<option value='" + i + "'>" + time + "</option>";

                sns._time_values.push(i);

                dt.setTime(dt.valueOf() + this.config.time_step * 60 * 1000);
                var diff = dt.getDate() != tdate ? 1 : 0; // moved or not to the next day

                i = diff * 24 * 60 + dt.getHours() * 60 + dt.getMinutes();
              }

              break;
          }

          if (options) {
            var ariaAttrs = scheduler._waiAria.lightboxSelectAttrString(time_option);

            var readonly = sns.readonly ? "disabled='disabled'" : "";
            html += "<select class='" + selectBoxClass + "' " + readonly + ariaAttrs + ">" + options + "</select> ";
          }
        }

        var defaults = scheduler._lightbox_controls.defaults.select;
        var defaultHeight = defaults ? defaults.height : 23;
        var height = defaultHeight || 30;
        return "<div style='height:" + height + "px;padding-top:0px;font-size:inherit;' class='dhx_section_time'>" + html + "<span style='font-weight:normal; font-size:10pt;'> &nbsp;&ndash;&nbsp; </span>" + html + "</div>";
      },
      set_value: function set_value(node, value, ev, config) {
        var cfg = scheduler.config;
        var s = node.getElementsByTagName("select");
        var map = config._time_format_order;
        var start_date, end_date;

        if (cfg.full_day) {
          if (!node._full_day) {
            var html = "<label class='dhx_fullday'><input type='checkbox' name='full_day' value='true'> " + scheduler.locale.labels.full_day + "&nbsp;</label></input>";
            if (!scheduler.config.wide_form) html = node.previousSibling.innerHTML + html;
            node.previousSibling.innerHTML = html;
            node._full_day = true;
          }

          var input = node.previousSibling.getElementsByTagName("input")[0];
          input.checked = scheduler.date.time_part(ev.start_date) === 0 && scheduler.date.time_part(ev.end_date) === 0;
          s[map[0]].disabled = input.checked;
          s[map[0] + s.length / 2].disabled = input.checked;

          input.onclick = function () {
            if (input.checked) {
              var obj = {};
              scheduler.form_blocks.time.get_value(node, obj, config);
              start_date = scheduler.date.date_part(obj.start_date);
              end_date = scheduler.date.date_part(obj.end_date);
              if (+end_date == +start_date || +end_date >= +start_date && (ev.end_date.getHours() !== 0 || ev.end_date.getMinutes() !== 0)) end_date = scheduler.date.add(end_date, 1, "day");
            } else {
              start_date = null;
              end_date = null;
            }

            s[map[0]].disabled = input.checked;
            s[map[0] + s.length / 2].disabled = input.checked;

            _fill_lightbox_select(s, 0, start_date || ev.start_date);

            _fill_lightbox_select(s, 4, end_date || ev.end_date);
          };
        }

        if (cfg.auto_end_date && cfg.event_duration) {
          var _update_lightbox_select = function _update_lightbox_select() {
            if (!(cfg.auto_end_date && cfg.event_duration)) {
              // setting may be disabled after the handler is attached
              return;
            }

            start_date = new Date(s[map[3]].value, s[map[2]].value, s[map[1]].value, 0, s[map[0]].value);
            end_date = new Date(start_date.getTime() + scheduler.config.event_duration * 60 * 1000);

            _fill_lightbox_select(s, 4, end_date);
          };

          for (var i = 0; i < 4; i++) {
            s[i].onchange = _update_lightbox_select;
          }
        }

        function _fill_lightbox_select(s, i, d) {
          var time_values = config._time_values;
          var direct_value = d.getHours() * 60 + d.getMinutes();
          var fixed_value = direct_value;
          var value_found = false;

          for (var k = 0; k < time_values.length; k++) {
            var t_v = time_values[k];

            if (t_v === direct_value) {
              value_found = true;
              break;
            }

            if (t_v < direct_value) fixed_value = t_v;
          }

          s[i + map[0]].value = value_found ? direct_value : fixed_value;

          if (!(value_found || fixed_value)) {
            s[i + map[0]].selectedIndex = -1; //show empty select in FF
          }

          s[i + map[1]].value = d.getDate();
          s[i + map[2]].value = d.getMonth();
          s[i + map[3]].value = d.getFullYear();
        }

        _fill_lightbox_select(s, 0, ev.start_date);

        _fill_lightbox_select(s, 4, ev.end_date);
      },
      get_value: function get_value(node, ev, config) {
        var s = node.getElementsByTagName("select");
        var map = config._time_format_order;
        ev.start_date = new Date(s[map[3]].value, s[map[2]].value, s[map[1]].value, 0, s[map[0]].value);
        ev.end_date = new Date(s[map[3] + 4].value, s[map[2] + 4].value, s[map[1] + 4].value, 0, s[map[0] + 4].value);

        if (!(s[map[3]].value && s[map[3] + 4].value)) {
          // use the previous date if start/end years are empty (outside lightbox range)
          var original = scheduler.getEvent(scheduler._lightbox_id);

          if (original) {
            ev.start_date = original.start_date;
            ev.end_date = original.end_date;
          }
        }

        if (ev.end_date <= ev.start_date) ev.end_date = scheduler.date.add(ev.start_date, scheduler.config.time_step, "minute");
        return {
          start_date: new Date(ev.start_date),
          end_date: new Date(ev.end_date)
        };
      },
      focus: function focus(node) {
        scheduler._focus(node.getElementsByTagName("select")[0]);
      }
    }
  };

  scheduler._setLbPosition = function (box) {
    if (!box) {
      return;
    }

    var scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop;
    var scrollLeft = window.pageXOffset || document.body.scrollLeft || document.documentElement.scrollLeft;
    var viewHeight = window.innerHeight || document.documentElement.clientHeight;
    if (scrollTop) // if vertical scroll on window
      box.style.top = Math.round(scrollTop + Math.max((viewHeight - box.offsetHeight) / 2, 0)) + "px";else // vertical scroll on body
      box.style.top = Math.round(Math.max((viewHeight - box.offsetHeight) / 2, 0) + 9) + "px"; // +9 for compatibility with auto tests
    // not quite accurate but used for compatibility reasons

    if (document.documentElement.scrollWidth > document.body.offsetWidth) // if horizontal scroll on the window
      box.style.left = Math.round(scrollLeft + (document.body.offsetWidth - box.offsetWidth) / 2) + "px";else // horizontal scroll on the body
      box.style.left = Math.round((document.body.offsetWidth - box.offsetWidth) / 2) + "px";
  };

  scheduler.showCover = function (box) {
    if (box) {
      box.style.display = "block";

      this._setLbPosition(box);
    }

    if (scheduler.config.responsive_lightbox) {
      document.documentElement.classList.add("dhx_cal_overflow_container");
      document.body.classList.add("dhx_cal_overflow_container");
    }

    this.show_cover();
  };

  scheduler.showLightbox = function (id) {
    if (!id) return;

    if (!this.callEvent("onBeforeLightbox", [id])) {
      if (this._new_event) this._new_event = null;
      return;
    }

    var box = this.getLightbox();
    this.showCover(box);

    this._fill_lightbox(id, box);

    this._waiAria.lightboxVisibleAttr(box);

    this.callEvent("onLightbox", [id]);
  };

  scheduler._fill_lightbox = function (id, box) {
    var ev = this.getEvent(id);
    var s = box.getElementsByTagName("span");
    var lightboxHeader = [];

    if (scheduler.templates.lightbox_header) {
      lightboxHeader.push("");
      var headerContent = scheduler.templates.lightbox_header(ev.start_date, ev.end_date, ev);
      lightboxHeader.push(headerContent);
      s[1].innerHTML = "";
      s[2].innerHTML = headerContent;
    } else {
      var headerDate = this.templates.event_header(ev.start_date, ev.end_date, ev);
      var headerTitle = (this.templates.event_bar_text(ev.start_date, ev.end_date, ev) || "").substr(0, 70); //IE6 fix;

      lightboxHeader.push(headerDate);
      lightboxHeader.push(headerTitle);
      s[1].innerHTML = headerDate;
      s[2].innerHTML = headerTitle;
    }

    this._waiAria.lightboxHeader(box, lightboxHeader.join(" "));

    var sns = this.config.lightbox.sections;

    for (var i = 0; i < sns.length; i++) {
      var current_sns = sns[i];

      var node = scheduler._get_lightbox_section_node(current_sns);

      var block = this.form_blocks[current_sns.type];
      var value = ev[current_sns.map_to] !== undefined ? ev[current_sns.map_to] : current_sns.default_value;
      block.set_value.call(this, node, value, ev, current_sns);
      if (sns[i].focus) block.focus.call(this, node);
    }

    scheduler._lightbox_id = id;
  };

  scheduler._get_lightbox_section_node = function (section) {
    return document.getElementById(section.id).nextSibling;
  };

  scheduler._lightbox_out = function (ev) {
    var sns = this.config.lightbox.sections;

    for (var i = 0; i < sns.length; i++) {
      var node = document.getElementById(sns[i].id);
      node = node ? node.nextSibling : node;
      var block = this.form_blocks[sns[i].type];
      var res = block.get_value.call(this, node, ev, sns[i]);
      if (sns[i].map_to != "auto") ev[sns[i].map_to] = res;
    }

    return ev;
  };

  scheduler._empty_lightbox = function (data) {
    var id = scheduler._lightbox_id;
    var ev = this.getEvent(id); //var box=this.getLightbox();

    this._lame_copy(ev, data);

    this.setEvent(ev.id, ev);

    this._edit_stop_event(ev, true);

    this.render_view_data();
  };

  scheduler.hide_lightbox = function (id) {
    scheduler.endLightbox(false, this.getLightbox());
  };

  scheduler.hideCover = function (box) {
    if (box) box.style.display = "none";
    this.hide_cover();

    if (scheduler.config.responsive_lightbox) {
      document.documentElement.classList.remove("dhx_cal_overflow_container");
      document.body.classList.remove("dhx_cal_overflow_container");
    }
  };

  scheduler.hide_cover = function () {
    if (this._cover) this._cover.parentNode.removeChild(this._cover);
    this._cover = null;
  };

  scheduler.show_cover = function () {
    if (this._cover) {
      return;
    }

    this._cover = document.createElement("div");
    this._cover.className = "dhx_cal_cover";
    document.body.appendChild(this._cover);
  };

  scheduler.save_lightbox = function () {
    var data = this._lightbox_out({}, this._lame_copy(this.getEvent(this._lightbox_id)));

    if (this.checkEvent("onEventSave") && !this.callEvent("onEventSave", [this._lightbox_id, data, this._new_event])) return;

    this._empty_lightbox(data);

    this.hide_lightbox();
  };

  scheduler.startLightbox = function (id, box) {
    this._lightbox_id = id;
    this._custom_lightbox = true;
    this._temp_lightbox = this._lightbox;
    this._lightbox = box;
    this.showCover(box);
  };

  scheduler.endLightbox = function (mode, box) {
    var box = box || scheduler.getLightbox();
    var event = scheduler.getEvent(this._lightbox_id);
    if (event) this._edit_stop_event(event, mode);
    if (mode) scheduler.render_view_data();
    this.hideCover(box);

    if (this._custom_lightbox) {
      this._lightbox = this._temp_lightbox;
      this._custom_lightbox = false;
    }

    this._temp_lightbox = this._lightbox_id = null; // in case of custom lightbox user only calls endLightbox so we need to reset _lightbox_id

    this._waiAria.lightboxHiddenAttr(box);

    this.callEvent("onAfterLightbox", []);
  };

  scheduler.resetLightbox = function () {
    if (scheduler._lightbox && !scheduler._custom_lightbox) scheduler._lightbox.parentNode.removeChild(scheduler._lightbox);
    scheduler._lightbox = null;
  };

  scheduler.cancel_lightbox = function () {
    if (this._lightbox_id) {
      this.callEvent("onEventCancel", [this._lightbox_id, this._new_event]);
    }

    this.hide_lightbox();
  };

  scheduler.hideLightbox = scheduler.cancel_lightbox; // GS-1650 need to use cancel in order to fire onEventCancel event, which is important to refresh the state of recurring series

  scheduler._init_lightbox_events = function () {
    this.getLightbox().onclick = function (e) {
      var src = e.target;
      if (!src.className) src = src.previousSibling;

      if (src && src.className && scheduler._getClassName(src).indexOf("dhx_btn_set") > -1) {
        // assistive software (e.g. jaws) can dispatch event on the top element of a button
        src = src.querySelector("[dhx_button]");
        if (!src) return;
      }

      var className = scheduler._getClassName(src);

      if (src && className) switch (className) {
        case "dhx_save_btn":
          scheduler.save_lightbox();
          break;

        case "dhx_delete_btn":
          var c = scheduler.locale.labels.confirm_deleting;

          scheduler._dhtmlx_confirm(c, scheduler.locale.labels.title_confirm_deleting, function () {
            scheduler.deleteEvent(scheduler._lightbox_id);
            scheduler._new_event = null; //clear flag, if it was unsaved event

            scheduler.hide_lightbox();
          });

          break;

        case "dhx_cancel_btn":
          scheduler.cancel_lightbox();
          break;

        default:
          if (src.getAttribute("dhx_button")) {
            scheduler.callEvent("onLightboxButton", [className, src, e]);
          } else {
            var index, block, sec;

            if (className.indexOf("dhx_custom_button") != -1) {
              if (className.indexOf("dhx_custom_button_") != -1) {
                index = src.parentNode.getAttribute("index");
                sec = src.parentNode.parentNode;
              } else {
                index = src.getAttribute("index");
                sec = src.parentNode;
                src = src.firstChild;
              }
            }

            if (index) {
              block = scheduler.form_blocks[scheduler.config.lightbox.sections[index].type];
              block.button_click(index, src, sec, sec.nextSibling);
            }
          }

          break;
      }
    };

    this.getLightbox().onkeydown = function (e) {
      var event = e || window.event;
      var target = e.target || e.srcElement;
      var buttonTarget = target.querySelector("[dhx_button]");

      if (!buttonTarget) {
        buttonTarget = target.parentNode.querySelector(".dhx_custom_button, .dhx_readonly");
      }

      switch ((e || event).keyCode) {
        case 32:
          {
            //space
            if ((e || event).shiftKey) return;

            if (buttonTarget && buttonTarget.click) {
              buttonTarget.click();
            }

            break;
          }

        case scheduler.keys.edit_save:
          if ((e || event).shiftKey) return;

          if (buttonTarget && buttonTarget.click) {
            buttonTarget.click();
          } else {
            scheduler.save_lightbox();
          }

          break;

        case scheduler.keys.edit_cancel:
          scheduler.cancel_lightbox();
          break;

        default:
          break;
      }
    };
  };

  scheduler.setLightboxSize = function () {
    var d = this._lightbox;
    if (!d) return;
    var con = d.childNodes[1];
    con.style.height = "0px";
    con.style.height = con.scrollHeight + "px";
    d.style.height = con.scrollHeight + scheduler.xy.lightbox_additional_height + "px";
    con.style.height = con.scrollHeight + "px"; //it is incredible , how ugly IE can be
  };

  scheduler._init_dnd_events = function () {
    scheduler.event(document.body, "mousemove", scheduler._move_while_dnd);
    scheduler.event(document.body, "mouseup", scheduler._finish_dnd);

    scheduler._init_dnd_events = function () {};
  };

  scheduler._move_while_dnd = function (e) {
    if (scheduler._dnd_start_lb) {
      if (!document.dhx_unselectable) {
        document.body.className += " dhx_unselectable";
        document.dhx_unselectable = true;
      }

      var lb = scheduler.getLightbox();
      var now = [e.pageX, e.pageY];
      lb.style.top = scheduler._lb_start[1] + now[1] - scheduler._dnd_start_lb[1] + "px";
      lb.style.left = scheduler._lb_start[0] + now[0] - scheduler._dnd_start_lb[0] + "px";
    }
  };

  scheduler._ready_to_dnd = function (e) {
    var lb = scheduler.getLightbox();
    scheduler._lb_start = [parseInt(lb.style.left, 10), parseInt(lb.style.top, 10)];
    scheduler._dnd_start_lb = [e.pageX, e.pageY];
  };

  scheduler._finish_dnd = function () {
    if (scheduler._lb_start) {
      scheduler._lb_start = scheduler._dnd_start_lb = false;
      document.body.className = document.body.className.replace(" dhx_unselectable", "");
      document.dhx_unselectable = false;
    }
  };

  scheduler.getLightbox = function () {
    //scheduler.config.wide_form=true;
    if (!this._lightbox) {
      var d = document.createElement("div");
      d.className = "dhx_cal_light";
      if (scheduler.config.wide_form) d.className += " dhx_cal_light_wide";
      if (scheduler.form_blocks.recurring) d.className += " dhx_cal_light_rec";
      if (scheduler.config.rtl) d.className += " dhx_cal_light_rtl";
      if (scheduler.config.responsive_lightbox) d.className += " dhx_cal_light_responsive";
      if (/msie|MSIE 6/.test(navigator.userAgent)) d.className += " dhx_ie6";
      d.style.visibility = "hidden";
      var html = this._lightbox_template;
      var buttons = this.config.buttons_left;
      var ariaAttr = "";

      for (var i = 0; i < buttons.length; i++) {
        ariaAttr = this._waiAria.lightboxButtonAttrString(buttons[i]);
        html += "<div " + ariaAttr + " class='dhx_btn_set dhx_" + (scheduler.config.rtl ? "right" : "left") + "_btn_set " + buttons[i] + "_set'><div dhx_button='1' class='" + buttons[i] + "'></div><div>" + scheduler.locale.labels[buttons[i]] + "</div></div>";
      }

      buttons = this.config.buttons_right;
      var rtl = scheduler.config.rtl;

      for (var i = 0; i < buttons.length; i++) {
        ariaAttr = this._waiAria.lightboxButtonAttrString(buttons[i]);
        html += "<div " + ariaAttr + " class='dhx_btn_set dhx_" + (rtl ? "left" : "right") + "_btn_set " + buttons[i] + "_set' style='float:" + (rtl ? "left" : "right") + ";'><div dhx_button='1' class='" + buttons[i] + "'></div><div>" + scheduler.locale.labels[buttons[i]] + "</div></div>";
      }

      html += "</div>";
      d.innerHTML = html;

      if (scheduler.config.drag_lightbox) {
        d.firstChild.onmousedown = scheduler._ready_to_dnd;

        d.firstChild.onselectstart = function () {
          return false;
        };

        d.firstChild.style.cursor = "move";

        scheduler._init_dnd_events();
      }

      this._waiAria.lightboxAttr(d);

      document.body.insertBefore(d, document.body.firstChild);
      this._lightbox = d;
      var sns = this.config.lightbox.sections;
      html = "";

      for (var i = 0; i < sns.length; i++) {
        var block = this.form_blocks[sns[i].type];
        if (!block) continue; //ignore incorrect blocks

        sns[i].id = "area_" + this.uid();
        var button = "";

        if (sns[i].button) {
          var ariaAttr = scheduler._waiAria.lightboxSectionButtonAttrString(this.locale.labels["button_" + sns[i].button]);

          button = "<div " + ariaAttr + " class='dhx_custom_button' index='" + i + "'><div class='dhx_custom_button_" + sns[i].button + "'></div><div>" + this.locale.labels["button_" + sns[i].button] + "</div></div>";
        }

        if (this.config.wide_form) {
          html += "<div class='dhx_wrap_section'>";
        }

        var label_name = this.locale.labels["section_" + sns[i].name];

        if (typeof label_name !== "string") {
          label_name = sns[i].name;
        }

        html += "<div id='" + sns[i].id + "' class='dhx_cal_lsection'>" + button + "<label>" + label_name + "</label></div>" + block.render.call(this, sns[i]);
        html += "</div>";
      }

      var ds = d.getElementsByTagName("div");

      for (var i = 0; i < ds.length; i++) {
        var t_ds = ds[i];

        var className = scheduler._getClassName(t_ds);

        if (className == "dhx_cal_larea") {
          t_ds.innerHTML = html;
          break;
        }
      } // bind labels to lightbox inputs


      scheduler._bindLightboxLabels(sns); //sizes


      this.setLightboxSize();

      this._init_lightbox_events(this);

      d.style.display = "none";
      d.style.visibility = "visible";
    }

    return this._lightbox;
  };

  scheduler._bindLightboxLabels = function (sections) {
    // link section labels to controls using label[for] attribute and label.onclick=control.focus as a fallback
    // label[for] is preferable for accessibility reasons
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      if (!section.id || !document.getElementById(section.id)) continue;
      var labelBlock = document.getElementById(section.id);
      var label = labelBlock.querySelector("label");

      var inputBlock = scheduler._get_lightbox_section_node(section);

      while (inputBlock && !inputBlock.querySelector) {
        inputBlock = inputBlock.nextSibling;
      }

      var fallback = true;

      if (inputBlock) {
        var input = inputBlock.querySelector("input, select, textarea");

        if (input) {
          section.inputId = input.id || "input_" + scheduler.uid();
          if (!input.id) input.id = section.inputId;
          label.setAttribute("for", section.inputId);
          fallback = false;
        }
      } // use control.focus if failed to bind input using label[for]


      if (fallback) {
        var control = scheduler.form_blocks[section.type];

        if (control.focus) {
          label.onclick = function (section) {
            return function () {
              var block = scheduler.form_blocks[section.type];

              var node = scheduler._get_lightbox_section_node(section);

              if (block && block.focus) block.focus.call(scheduler, node);
            };
          }(section);
        }
      }
    }
  };

  scheduler.attachEvent("onEventIdChange", function (old_id, new_id) {
    if (this._lightbox_id == old_id) this._lightbox_id = new_id;
  });
  scheduler._lightbox_template = "<div class='dhx_cal_ltitle'><span class='dhx_mark'>&nbsp;</span><span class='dhx_time'></span><span class='dhx_title'></span></div><div class='dhx_cal_larea'></div>";
}