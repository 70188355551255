function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import "./css/terrace.skin";
import assert from "./core/common/assert";
import common_errors from "./core/common_errors";
import connector from "./core/connector";
import schedulerCore from "./core/scheduler";
import common from "./core/common";
import wai_aria from "./core/wai_aria";
import utils from "./core/utils/utils";
import dom_helpers from "./core/utils/dom_helpers";
import env from "./utils/env";
import bluebird from "./core/utils/bluebird";
import destructor from "./core/destructor";
import ajax from "./core/utils/ajax";
import base from "./core/base";
import config from "./core/config";
import event from "./core/event";
import event_highlight from "./core/event_highlight";
import load from "./core/load";
import lightbox from "./core/lightbox";
import touch from "./core/touch";
import dhtmlx_suite_hooks from "./core/dhtmlx/dhtmlx_suite_hooks";
import skins from "./core/skins";
import jquery from "./core/jquery";
import delay_render from "./core/delay_render";
import dataProcessorExtend from "./core/dataprocessor/dataprocessor";
import message from "./core/message";
import i18nFactory from "./locale";
import voidFirst from "./publish_helpers/void_script_first";
import voidSecond from "./publish_helpers/void_script_second";
import voidThird from "./publish_helpers/void_script_third";
export default function (extensionManager) {
  var scheduler = {
    version: VERSION
  };
  common_errors(scheduler);
  schedulerCore(scheduler);
  common(scheduler);
  wai_aria(scheduler); //utils(scheduler);

  scheduler.utils = utils;
  scheduler.$domHelpers = dom_helpers;
  scheduler.utils.dom = dom_helpers;
  scheduler.uid = utils.uid;
  scheduler.mixin = utils.mixin;
  scheduler.defined = utils.defined;
  scheduler.assert = assert(scheduler);
  scheduler.copy = utils.copy; //old api compatibility

  scheduler._getFocusableNodes = dom_helpers.getFocusableNodes;
  scheduler._getClassName = dom_helpers.getClassName;
  scheduler._locate_css = dom_helpers.locateCss;
  var messageApi = message(scheduler);
  scheduler.utils.mixin(scheduler, messageApi);
  scheduler.env = scheduler.$env = env;
  scheduler.Promise = bluebird;
  destructor(scheduler);
  ajax(scheduler);
  voidFirst(scheduler);
  base(scheduler);
  config(scheduler);
  event(scheduler);
  event_highlight(scheduler);
  load(scheduler);
  lightbox(scheduler);
  touch(scheduler);
  dhtmlx_suite_hooks(scheduler);
  voidSecond(scheduler);
  skins(scheduler);
  jquery(scheduler);
  delay_render(scheduler);
  dataProcessorExtend(scheduler);
  voidThird(scheduler);
  connector(scheduler);
  var i18n = i18nFactory();
  scheduler.i18n = {
    addLocale: i18n.addLocale,
    setLocale: function setLocale(locale) {
      if (typeof locale === "string") {
        var localeObject = i18n.getLocale(locale);

        if (!localeObject) {
          localeObject = i18n.getLocale("en");
        }

        scheduler.locale = localeObject;
      } else if (locale) {
        if (!scheduler.locale) {
          scheduler.locale = locale;
        } else {
          for (var i in locale) {
            if (locale[i] && _typeof(locale[i]) === "object") {
              if (!scheduler.locale[i]) {
                scheduler.locale[i] = {};
              }

              scheduler.mixin(scheduler.locale[i], locale[i], true);
            } else {
              scheduler.locale[i] = locale[i];
            }
          }
        }
      }
    },
    getLocale: i18n.getLocale
  };
  scheduler.i18n.setLocale("en");
  scheduler.ext = {};
  var activePlugins = {};

  scheduler.plugins = function (config) {
    var extensionList = getExtensionList(config, {
      treetimeline: ["timeline"],
      daytimeline: ["timeline"],
      outerdrag: ["legacy"]
    }, {
      legacy: 1,
      limit: 1,
      timeline: 2,
      daytimeline: 3,
      treetimeline: 3,
      outerdrag: 6
    });
    extensionList.forEach(function (name) {
      if (!activePlugins[name]) {
        var plugin = extensionManager.getExtension(name);

        if (plugin) {
          plugin(scheduler);
          activePlugins[name] = true;
        } else {
          throw new Error("unknown plugin " + name);
        }
      }
    });
  };

  function getExtensionList(config, dependencies, priorities) {
    var result = [];

    for (var i in config) {
      if (config[i]) {
        var extension = i.toLowerCase();

        if (dependencies[extension]) {
          dependencies[extension].forEach(function (dep) {
            var dependencyName = dep.toLowerCase();

            if (!config[dependencyName]) {
              result.push(dependencyName);
            }
          });
        }

        result.push(extension);
      }
    }

    result.sort(function (a, b) {
      var orderA = priorities[a] || 0;
      var orderB = priorities[b] || 0;

      if (orderA > orderB) {
        return 1;
      } else if (orderA < orderB) {
        return -1;
      } else {
        return 0;
      }
    });
    return result;
  }

  return scheduler;
}